import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import AutoComplete from '@mui/material/Autocomplete';
import { CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import _ from 'lodash';

import { TextField } from 'ui/components/TextField/TextField';
import { activeUserHasPermission } from 'services/user/redux';
import { Pagination } from 'services/search';
import { initialPagination } from 'ui/components/Table/ItemsTable';
import { PaginationDirection } from 'services/url';
import { fetchReceiptById, Receipt } from 'services/receiving';

import { ReceiptAsyncAutocompleteProps } from './types';
import {
  receiptAutocompleteFilterOptions,
  resolvedReceiptFunction,
} from './helpers';

import { useAsyncAutocomplete } from '../Autocomplete/hooks';

const initialReceiptPagination: Pagination = {
  ...initialPagination,
  pageSize: 50,
  sort: { sortBy: 'dateCreated', direction: PaginationDirection.Ascending },
};

const ReceiptAsyncAutocomplete: React.FC<ReceiptAsyncAutocompleteProps> = (
  props
) => {
  const {
    onChange,
    dataQa,
    permissions = [],
    disabled,
    label,
    value,
    placeholder,
    error,
    required,
    additionalInputProps,
    expands,
    helperText,
    customQuickSearchColumns,
  } = props;

  const {
    data: receipt,
    isLoading,
    innerValue,
    open,
    onCloseAutocomplete,
    onNextPage,
    onOpenAutocomplete,
    setSearchValue,
    setInnerValue,
  } = useAsyncAutocomplete(
    value,
    resolvedReceiptFunction(expands, customQuickSearchColumns),
    fetchReceiptById,
    initialReceiptPagination
  );

  const canEdit = useSelector(activeUserHasPermission(permissions));

  const isDisabled = disabled || !canEdit;

  const handleAutocompleteInputChange = (
    e: any,
    val: string,
    reason: string
  ) => {
    // reason why this event is triggered
    // it can be 'input', 'clear' and 'reset'
    if (reason === 'input') {
      if (!val) {
        onOpenAutocomplete();
        setInnerValue(null);
      }
      setSearchValue(val || null);
      onOpenAutocomplete();
      return;
    }

    if (reason === 'clear') {
      onChange(null);
    }

    setSearchValue(null);
  };

  const handleChange = (e: any, receipt: Receipt | null) => {
    if (receipt && (receipt.id === null || receipt.id < 0)) {
      return;
    }

    setInnerValue(receipt);
    onChange(receipt);
  };

  return (
    <>
      <AutoComplete
        className="redesign"
        // TODO : RESTYLING : minor style adjustment needed for now until we use the FBOAutoSelect component
        style={{ marginTop: '0px', marginBottom: '0px' }}
        options={receipt}
        open={open}
        disabled={isDisabled}
        value={innerValue}
        onOpen={onOpenAutocomplete}
        onClose={onCloseAutocomplete}
        onChange={handleChange}
        onInputChange={handleAutocompleteInputChange}
        getOptionLabel={(option: Receipt) => option.number || ''}
        filterOptions={receiptAutocompleteFilterOptions()}
        noOptionsText={isLoading ? 'Loading...' : 'No options'}
        isOptionEqualToValue={(option, val) => option.id === val.id}
        ListboxProps={{
          onScroll: (event: React.SyntheticEvent) => {
            const node = event.currentTarget;
            if (
              Math.ceil(node.scrollTop + node.clientHeight) ===
              node.scrollHeight
            ) {
              onNextPage();
            }
          },
          role: 'list-box',
        }}
        renderInput={(params) => {
          const endAdornment = params.InputProps.endAdornment as any;

          return (
            <TextField
              // we are omitting props that would overwrite our styling in TextField
              {..._.omit(params, 'variant', 'size', 'InputLabelProps')}
              className="redesign"
              label={label}
              placeholder={placeholder}
              error={error}
              required={required}
              helperText={helperText}
              InputProps={{
                ...params.InputProps,
                ...additionalInputProps,
                endAdornment: (
                  <React.Fragment>
                    {isLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {endAdornment}
                  </React.Fragment>
                ),
              }}
              inputProps={{
                'data-qa': dataQa,
                'data-lpignore': 'true',
                ...params.inputProps,
              }}
            />
          );
        }}
        clearIcon={
          dataQa && (
            <CloseIcon
              fontSize="small"
              data-qa={`${dataQa}-autocomplete-clear-icon`}
            />
          )
        }
        popupIcon={
          dataQa && (
            <ArrowDropDownIcon
              fontSize="small"
              data-qa={`${dataQa}-autocomplete-dropdown-icon`}
            />
          )
        }
      />
    </>
  );
};

export default memo(ReceiptAsyncAutocomplete);
