import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Box, Grid } from '@mui/material';
import moment from 'moment';
import _ from 'lodash';

import { getCarriers, Carrier } from 'services/carriers';
import { TextField } from 'ui/components/TextField/TextField';
import { useGetIntlDateFormatString } from 'helpers';

import { GeneralTabProps } from './types';

const GeneralTab: React.FC<GeneralTabProps> = (props) => {
  const { activeReceipt } = props;

  const { items: carriers } = useSelector(getCarriers);
  const intlFormatDate = useGetIntlDateFormatString();

  const issuedDate = useMemo(() => {
    if (activeReceipt.dateCreated) {
      const date = activeReceipt.dateCreated;
      return moment(date).format(intlFormatDate);
    }
    return null;
  }, [activeReceipt.dateCreated, intlFormatDate]);

  const carrier = useMemo(() => {
    if (activeReceipt.purchaseOrder) {
      const purchaseOrder = activeReceipt.purchaseOrder;
      return carriers.find((c: Carrier) => c.id === purchaseOrder.carrierId)!;
    }
    return null;
  }, [activeReceipt.purchaseOrder, carriers]);

  const carrierService = useMemo(() => {
    if (activeReceipt.purchaseOrder && carrier) {
      const purchaseOrder = activeReceipt.purchaseOrder;
      return carrier.carrierServiceList.find(
        (c) => c.id === purchaseOrder.carrierServiceId
      )!;
    }
    return null;
  }, [activeReceipt.purchaseOrder, carrier]);

  return (
    <Box p={3} pb={2}>
      <Grid container spacing={2}>
        <Grid container item xs={5} spacing={2}>
          <Grid item xs={4}>
            <TextField
              label="Vendor"
              value={_.get(activeReceipt, 'purchaseOrder.vendor.name', null)}
              readOnly
            />
          </Grid>
          <Grid item xs={4}>
            <TextField label="Issued" value={issuedDate} readOnly />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="FOB"
              value={_.get(activeReceipt, 'purchaseOrder.fobPoint.name', null)}
              readOnly
            />
          </Grid>
        </Grid>
        <Grid container item xs={4} spacing={2}>
          <Grid item xs={4}>
            <TextField
              label="Customer SO"
              value={
                activeReceipt.purchaseOrder
                  ? activeReceipt.purchaseOrder.customerSONumber
                  : null
              }
              readOnly
              fullWidth={false}
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Vendor SO"
              value={
                activeReceipt.purchaseOrder
                  ? activeReceipt.purchaseOrder.vendorSONumber
                  : null
              }
              readOnly
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              label="Carrier"
              value={carrier ? carrier.name : null}
              readOnly
            />
          </Grid>
        </Grid>
        <Grid container item xs={3} spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Service"
              value={carrierService ? carrierService.name : null}
              readOnly
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default memo(GeneralTab);
