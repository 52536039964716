import React from 'react';
import { GlobalSettingsBannerProps } from './types';
import { Box, Typography } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useGlobalSettingsBannerStyle } from './styled';
import { Routes as CommerceRoutes } from '../../navigation/routes';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';

export const GlobalSettingsBanner: React.FC<GlobalSettingsBannerProps> = ({
  showConfigureSettingsButton = true,
}) => {
  const classes = useGlobalSettingsBannerStyle();

  return (
    <Box
      className={classes.wrapper}
      data-qa="sellware-global-settings-banner"
      sx={{
        margin: 'calc(-18rem/16) calc(-18rem/16) calc(18rem/16)',
      }}
    >
      <ErrorOutlineIcon
        sx={{
          width: '20px',
          height: '20px',
          color: '#A50808',
        }}
      />
      <Typography className={classes.captionText}>
        Configure Fishbowl Commerce Settings:
      </Typography>
      <Typography variant="body1" flexGrow={1}>
        Your Channels cannot be synchronized until Settings are configured.
      </Typography>
      {showConfigureSettingsButton && (
        <FBOButton
          variant="primary"
          color="negative"
          size="medium"
          href={CommerceRoutes.SettingsPage}
          data-qa="configure-sellware-settings-button"
          linkStyledAsButton
        >
          Configure Fishbowl Commerce Settings
        </FBOButton>
      )}
    </Box>
  );
};
