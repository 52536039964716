import { makeStyles } from '@mui/styles';
import { Theme } from '@mui/material/styles';

import { NetworkSpinnerWrapperProps } from './types';

export const useSpinnerStyle = makeStyles<Theme, NetworkSpinnerWrapperProps>({
  spinner: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
    zIndex: ({ zIndex }) => (typeof zIndex === 'number' ? zIndex + 1 : 'auto'),
    color: ({ spinnerColor }) => (spinnerColor ? spinnerColor : 'initial'),
  },
  wrapper: {
    position: 'relative',
  },
  background: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: ({ backgroundColor }) =>
      backgroundColor ? backgroundColor : 'rgba(255, 255, 255, 0.38)',
    // TODO remove ts-ignore
    // @ts-ignore
    zIndex: ({ zIndex }) => (zIndex ? zIndex : 'auto'),
  },
});
