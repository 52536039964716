import { commerceApi } from 'services/api/config';

export const register = async (
  tenantId: string,
  appId: string,
  appSecret: string
) => {
  const response = await commerceApi({
    url: `/commerce/v1/registrations`,
    method: 'POST',
    data: {
      'app-id': appId,
      'app-secret': appSecret,
      'sellware-tenant-id': tenantId,
    },
  });

  return response.data;
};

export const reconnect = async (
  tenantId: string,
  appId: string,
  appSecret: string
) => {
  const response = await commerceApi({
    url: `/commerce/v1/registrations`,
    method: 'PUT',
    data: {
      'app-id': appId,
      'app-secret': appSecret,
      'sellware-tenant-id': tenantId,
    },
  });

  return response.data;
};

export const getConnectionStatus = async () => {
  const response = await commerceApi({
    url: `/commerce/v1/connection/status`,
    method: 'GET',
  });

  return response.data;
};
