import { State } from 'app/redux';

const getCommerce = (state: State) => state.commerce;

export const getCommerceAccountIsOrWasConnected = (state: State) =>
  !!getCommerce(state).connection;

export const getCommerceAccountIsDisconnected = (state: State) =>
  getCommerce(state).connection === 'DISCONNECTED';

export const getCommerceAccountIsConfigured = (state: State) =>
  getCommerce(state).configuration === 'CONFIGURED';
