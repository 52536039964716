import { ReactComponent as Accounting } from './assets/Accounting.svg';
import { ReactComponent as Add } from './assets/Add.svg';
import { ReactComponent as AddCircle } from './assets/AddCircle.svg';
import { ReactComponent as FBOAddCircle } from './styleguide/FBOAddCircle.svg';
import { ReactComponent as AddNew } from './assets/AddNew.svg';
import { ReactComponent as Analytics } from './assets/Analytics.svg';
import { ReactComponent as ArrowBack } from './assets/ArrowBack.svg';
import { ReactComponent as ArrowCollapsed } from './assets/ArrowCollapsed.svg';
import { ReactComponent as ArrowDownward } from './assets/ArrowDownward.svg';
import { ReactComponent as ArrowExpanded } from './assets/ArrowExpanded.svg';
import { ReactComponent as ArrowForward } from './assets/ArrowForward.svg';
import { ReactComponent as ArrowUpward } from './assets/ArrowUpward.svg';
import { ReactComponent as AssignmentDownload } from './assets/AssignmentDownload.svg';
import { ReactComponent as AssignmentLate } from './assets/AssignmentLate.svg';
import { ReactComponent as AssignmentLines } from './assets/AssignmentLines.svg';
import { ReactComponent as AssignmentReturn } from './assets/AssignmentReturn.svg';
import { ReactComponent as AttachMoney } from './assets/AttachMoney.svg';
import { ReactComponent as Back } from './assets/Back.svg';
import { ReactComponent as BorderInner } from './assets/BorderInner.svg';
import { ReactComponent as Box } from './assets/Box.svg';
import { ReactComponent as Cart } from './assets/Cart.svg';
import { ReactComponent as Circle } from './assets/Circle.svg';
import { ReactComponent as Close } from './assets/Close.svg';
import { ReactComponent as CloseCircle } from './assets/CloseCircle.svg';
import { ReactComponent as CloseTag } from './assets/CloseTag.svg';
import { ReactComponent as Code } from './assets/Code.svg';
import { ReactComponent as ConnectPlug } from './assets/ConnectPlug.svg';
import { ReactComponent as Consignment } from './assets/Consignment.svg';
import { ReactComponent as Copy } from './assets/Copy.svg';
import { ReactComponent as CreditReturn } from './assets/CreditReturn.svg';
import { ReactComponent as Dashboard } from './assets/Dashboard.svg';
import { ReactComponent as Delete } from './assets/Delete.svg';
import { ReactComponent as DoneAll } from './assets/DoneAll.svg';
import { ReactComponent as Download } from './assets/Download.svg';
import { ReactComponent as Duplicate } from './assets/Duplicate.svg';
import { ReactComponent as Edit } from './styleguide/Edit.svg';
import { ReactComponent as Email } from './assets/Email.svg';
import { ReactComponent as Eye } from './styleguide/Eye.svg';
import { ReactComponent as EyeClosed } from './styleguide/EyeClosed.svg';
import { ReactComponent as FBOUncheckedCircle } from './styleguide/FBOUncheckedCircle.svg';
import { ReactComponent as FBOCheckedCircle } from './styleguide/FBOCheckedCircle.svg';
import { ReactComponent as FBOIndeterminateCircle } from './styleguide/FBOIndeterminateCircle.svg';
import { ReactComponent as FBOCaratRight } from './assets/FBOCaratRight.svg';
import { ReactComponent as FBOCaratRightSmall } from './assets/FBOCaratRightSmall.svg';
import { ReactComponent as FBOExport } from './assets/FBOExport.svg';
import { ReactComponent as FBOFlowMaterials } from './assets/FBOFlowMaterials.svg';
import { ReactComponent as FBOFlowOther } from './assets/FBOFlowOther.svg';
import { ReactComponent as FBOFlowPurchasing } from './assets/FBOFlowPurchasing.svg';
import { ReactComponent as FBOFlowSales } from './assets/FBOFlowSales.svg';
import { ReactComponent as General } from './assets/General.svg';
import { ReactComponent as Help } from './assets/Help.svg';
import { ReactComponent as Integrations } from './assets/Integrations.svg';
import { ReactComponent as Inventory } from './assets/Inventory.svg';
import { ReactComponent as Items } from './assets/Items.svg';
import { ReactComponent as Leave } from './styleguide/Leave.svg';
import { ReactComponent as LeavePage } from './assets/LeavePage.svg';
import { ReactComponent as LightBulb } from './assets/LightBulb.svg';
import { ReactComponent as List } from './assets/List.svg';
import { ReactComponent as Location } from './assets/Location.svg';
import { ReactComponent as LogoMark } from './assets/LogoMark.svg';
import { ReactComponent as LogoWhite } from './assets/LogoWhite.svg';
import { ReactComponent as LogoWithText } from './assets/LogoWithText.svg';

import { ReactComponent as Bell } from './styleguide/Bell.svg';
import { ReactComponent as Calendar } from './styleguide/Calendar.svg';
import { ReactComponent as CaratUp } from './styleguide/CaratUp.svg';
import { ReactComponent as CheckCircle } from './styleguide/CheckCircle.svg';
import { ReactComponent as CycleCircle } from './styleguide/CycleCircle.svg';
import { ReactComponent as DefaultCompanyLogo } from './styleguide/DefaultCompanyLogo.svg';
import { ReactComponent as IconArrowUp } from './styleguide/IconArrowUp.svg';
import { ReactComponent as IconArrowDown } from './styleguide/IconArrowDown.svg';
import { ReactComponent as IconAvatar } from './styleguide/IconAvatar.svg';
import { ReactComponent as IconCaratFilled } from './styleguide/IconCaratFilled.svg';
import { ReactComponent as IconCaratRight } from './styleguide/IconCaratRight.svg';
import { ReactComponent as IconClose } from './styleguide/IconClose.svg';
import { ReactComponent as IconCopy } from './styleguide/IconCopy.svg';
import { ReactComponent as IconEdit } from './styleguide/IconEdit.svg';
import { ReactComponent as IconFlashFilledLightning } from './styleguide/IconFlashFilledLightning.svg';
import { ReactComponent as IconKebab } from './styleguide/IconKebab.svg';
import { ReactComponent as IconLinkHorizontal } from './styleguide/IconLinkHorizontal.svg';
import { ReactComponent as IconLocation } from './styleguide/IconLocation.svg';
import { ReactComponent as IconNote } from './styleguide/IconNote.svg';
import { ReactComponent as IconRefund } from './styleguide/IconRefund.svg';
import { ReactComponent as IconSave } from './styleguide/IconSave.svg';
import { ReactComponent as IconSales } from './styleguide/IconSales.svg';
import { ReactComponent as IconSplit } from './styleguide/IconSplit.svg';
import { ReactComponent as Info } from './styleguide/Info.svg';
import { ReactComponent as ImagePhotoPicture } from './styleguide/ImagePhotoPicture.svg';
import { ReactComponent as MagnifyingGlass } from './styleguide/MagnifyingGlass.svg';
import { ReactComponent as MenuSandbox } from './styleguide/MenuSandbox.svg';
import { ReactComponent as MenuHome } from './styleguide/MenuHome.svg';
import { ReactComponent as MenuMaterials } from './styleguide/MenuMaterials.svg';
import { ReactComponent as MenuSales } from './styleguide/MenuesSales.svg';
import { ReactComponent as MenuPurchasing } from './styleguide/MenuPurchasing.svg';
import { ReactComponent as MenuAccounting } from './styleguide/MenuAccounting.svg';
import { ReactComponent as MenuManufacturing } from './styleguide/MenuManufacturing.svg';
import { ReactComponent as MenuReports } from './styleguide/MenuReports.svg';
import { ReactComponent as MenuCommerce } from './styleguide/MenuCommerce.svg';
import { ReactComponent as MenuIntegrations } from './styleguide/MenuIntegrations.svg';
import { ReactComponent as MenuHelp } from './styleguide/MenuHelp.svg';
import { ReactComponent as MenuRemoteSupport } from './styleguide/MenuRemoteSupport.svg';
import { ReactComponent as MenuSettings } from './styleguide/MenuSettings.svg';
import { ReactComponent as DownwardExpandArrow } from './styleguide/DownwardExpandArrow.svg';
import { ReactComponent as FBOAccountMapping } from './styleguide/FBOAccountMapping.svg';
import { ReactComponent as FBOCaratDown } from './styleguide/FBOCaratDown.svg';
import { ReactComponent as FBOClose } from './styleguide/FBOClose.svg';
import { ReactComponent as FBOCloseSmall } from './styleguide/FBOCloseSmall.svg';
import { ReactComponent as FBOCompany } from './styleguide/FBOCompany.svg';
import { ReactComponent as FBOCycle } from './styleguide/FBOCycle.svg';
import { ReactComponent as UpwardCarat } from './styleguide/UpwardCarat.svg';
import { ReactComponent as FBOLock } from './styleguide/FBOLock.svg';
import { ReactComponent as FBOUnlock } from './styleguide/FBOUnlock.svg';

import { ReactComponent as FBOPhotoPicture } from './styleguide/FBOPhotoPicture.svg';
import { ReactComponent as TrackingAim } from './styleguide/TrackingAim.svg';
import { ReactComponent as FBOPicking } from './styleguide/FBOPicking.svg';
import { ReactComponent as FBOShipping } from './styleguide/FBOShipping.svg';
import { ReactComponent as FBOLeavePage } from './styleguide/FBOLeavePage.svg';
import { ReactComponent as FBOInStock } from './styleguide/FBOInStock.svg';
import { ReactComponent as FBONoStock } from './styleguide/FBONoStock.svg';
import { ReactComponent as FBOPartialStock } from './styleguide/FBOPartialStock.svg';

import { ReactComponent as Manufacturing } from './assets/Manufacturing.svg';
import { ReactComponent as MaintenanceTools } from './assets/MaintenanceTools.svg';
import { ReactComponent as Materials } from './assets/Materials.svg';
import { ReactComponent as Measurements } from './assets/Measurements.svg';
import { ReactComponent as Menu } from './assets/Menu.svg';
import { ReactComponent as MiscSale } from './assets/MiscSale.svg';
import { ReactComponent as Mode } from './assets/Mode.svg';
import { ReactComponent as Monitor } from './assets/Monitor.svg';
import { ReactComponent as MoreVertical } from './assets/MoreVertical.svg';
import { ReactComponent as Move } from './assets/Move.svg';
import { ReactComponent as FBOMove } from './styleguide/FBOMove.svg';
import { ReactComponent as Pause } from './assets/Pause.svg';
import { ReactComponent as PDF } from './assets/PDF.svg';
import { ReactComponent as PhotoPlaceHolder } from './assets/PhotoPlaceHolder.svg';
import { ReactComponent as PinChecked } from './assets/PinChecked.svg';
import { ReactComponent as Place } from './assets/Place.svg';
import { ReactComponent as PlayArrow } from './assets/PlayArrow.svg';
import { ReactComponent as Print } from './assets/Print.svg';
import { ReactComponent as Printing } from './assets/Printing.svg';
import { ReactComponent as Purchase } from './assets/Purchase.svg';
import { ReactComponent as PurchaseOutline } from './assets/PurchaseOutline.svg';
import { ReactComponent as RecentlyUsed } from './assets/RecentlyUsed.svg';
import { ReactComponent as Refresh } from './assets/Refresh.svg';
import { ReactComponent as FBORefresh } from './styleguide/FBORefresh.svg';
import { ReactComponent as Refund } from './assets/Refund.svg';
import { ReactComponent as Repeat } from './assets/Repeat.svg';
import { ReactComponent as Reports } from './assets/Reports.svg';
import { ReactComponent as Sales } from './assets/Sales.svg';
import { ReactComponent as SalesOutline } from './assets/SalesOutline.svg';
import { ReactComponent as Save } from './assets/Save.svg';
import { ReactComponent as Scale } from './assets/Scale.svg';
import { ReactComponent as Security } from './assets/Security.svg';
import { ReactComponent as Setup } from './assets/Setup.svg';
import { ReactComponent as ShippingQuote } from './assets/ShippingQuote.svg';
import { ReactComponent as SubCircle } from './assets/SubCircle.svg';
import { ReactComponent as FBOSubCircle } from './styleguide/FBOSubCircle.svg';
import { ReactComponent as TransferOrder } from './assets/TransferOrder.svg';
import { ReactComponent as TrashCan } from './styleguide/TrashCan.svg';
import { ReactComponent as Tune } from './styleguide/Tune.svg';
import { ReactComponent as Upload } from './assets/Upload.svg';
import { ReactComponent as User } from './assets/User.svg';
import { ReactComponent as Warning } from './assets/Warning.svg';
import { ReactComponent as Widgets } from './assets/Widgets.svg';
import { ReactComponent as FBOWarningCircle } from './assets/FBOWarningCircle.svg';
import { ReactComponent as FBORightCircle } from './assets/FBORightCircle.svg';
import { ReactComponent as FBOWarehouse } from './assets/FBOWarehouse.svg';
import { ReactComponent as FBOCircleCheck } from './assets/FBOCircleCheck.svg';
import { ReactComponent as FBOEdit } from './assets/FBOEdit.svg';
import { ReactComponent as FBOPrint } from './assets/FBOPrint.svg';
import { ReactComponent as FBOEmail } from './assets/FBOEmail.svg';
import { ReactComponent as FBOExcel } from './assets/FBOExcel.svg';
import { ReactComponent as FBOFullscreen } from './assets/FBOFullscreen.svg';
import { ReactComponent as FBOFullscreenContract } from './assets/FBOFullscreenContract.svg';
import { ReactComponent as FBOPdf } from './assets/FBOPdf.svg';
import { ReactComponent as QuickBook } from './styleguide/QuickBook.svg';
import { ReactComponent as Xero } from './styleguide/Xero.svg';
import { ReactComponent as FBOQuickTransfer } from './assets/FBOQuickTransfer.svg';

import { ReactComponent as FBOImport } from './assets/FBOImport.svg';
import { ReactComponent as FBOReport } from './assets/FBOReport.svg';
import { ReactComponent as FBORecycleSmall } from './assets/FBORecycleSmall.svg';
import { ReactComponent as FBODeleteSmall } from './assets/FBODeleteSmall.svg';
import { ReactComponent as FBODuplicateSmall } from './assets/FBODuplicateSmall.svg';
import { ReactComponent as FBOReturnSmall } from './assets/FBOReturnSmall.svg';
import { ReactComponent as FBODocumentSmall } from './assets/FBODocumentSmall.svg';
import { ReactComponent as FBOCloseCircleSmall } from './assets/FBOCloseCircleSmall.svg';
import { ReactComponent as FBOAddCircleSmall } from './assets/FBOAddCircleSmall.svg';
import { ReactComponent as FBOEmailSmall } from './assets/FBOEmailSmall.svg';
import { ReactComponent as FBOReportSmall } from './assets/FBOReportSmall.svg';
import { ReactComponent as FBOPlayCircleSmall } from './assets/FBOPlayCircleSmall.svg';
import { ReactComponent as FBOLockedSmall } from './assets/FBOLockedSmall.svg';
import { ReactComponent as FBOPauseCircleSmall } from './assets/FBOPauseCircleSmall.svg';
import { ReactComponent as FBOStopCircleSmall } from './assets/FBOStopCircleSmall.svg';
import { ReactComponent as FBOCircleCheckedSmall } from './assets/FBOCircleCheckedSmall.svg';
import { ReactComponent as FBORedoSmall } from './assets/FBORedoSmall.svg';
import { ReactComponent as FBOQuestionMarkInfo } from './assets/FBOQuestionMarkInfo.svg';
import { ReactComponent as FBOArrowUp } from './assets/FBOArrowUp.svg';
import { ReactComponent as FBOArrowDown } from './assets/FBOArrowDown.svg';
import { ReactComponent as FBOFileDocument } from './assets/FBOFileDocument.svg';
import { ReactComponent as FBODocumentApprovalSmall } from './assets/FBODocumentApprovalSmall.svg';

export enum IconNames {
  Accounting = 'Accounting',
  Add = 'Add',
  AddCircle = 'AddCircle',
  FBOAddCircle = 'FBOAddCircle',
  AddNew = 'AddNew',
  Analytics = 'Analytics',
  ArrowBack = 'ArrowBack',
  ArrowCollapsed = 'ArrowCollapsed',
  ArrowDownward = 'ArrowDownward',
  ArrowExpanded = 'ArrowExpanded',
  ArrowForward = 'ArrowForward',
  ArrowUpward = 'ArrowUpward',
  AssignmentDownload = 'AssignmentDownload',
  AssignmentLate = 'AssignmentLate',
  AssignmentLines = 'AssignmentLines',
  AssignmentReturn = 'AssignmentReturn',
  AttachMoney = 'AttachMoney',
  Back = 'Back',
  Bell = 'Bell',
  BorderInner = 'BorderInner',
  Box = 'Box',
  Calendar = 'Calendar',
  CaratUp = 'CaratUp',
  Cart = 'Cart',
  Circle = 'Circle',
  CheckCircle = 'CheckCircle',
  CycleCircle = 'CycleCircle',
  Close = 'Close',
  CloseCircle = 'CloseCircle',
  CloseTag = 'CloseTag',
  Code = 'Code',
  ConnectPlug = 'ConnectPlug',
  Consignment = 'Consignment',
  Copy = 'Copy',
  CreditReturn = 'CreditReturn',
  Dashboard = 'Dashboard',
  DefaultCompanyLogo = 'DefaultCompanyLogo',
  Delete = 'Delete',
  DoneAll = 'DoneAll',
  Download = 'Download',
  DownwardExpandArrow = 'DownwardExpandArrow',
  Duplicate = 'Duplicate',
  Edit = 'Edit',
  Email = 'Email',
  Eye = 'Eye',
  EyeClosed = 'EyeClosed',
  FBOUncheckedCircle = 'FBOUncheckedCircle',
  FBOCheckedCircle = 'FBOCheckedCircle',
  FBOIndeterminateCircle = 'FBOIndeterminateCircle',
  FBOAccountMapping = 'FBOAccountMapping',
  FBOCaratRight = 'FBOCaratRight',
  FBOCaratDown = 'FBOCaratDown',
  FBOCaratRightSmall = 'FBOCaratRightSmall',
  FBOExport = 'FBOExport',
  FBOFlowMaterials = 'FBOFlowMaterials',
  FBOFlowOther = 'FBOFlowOther',
  FBOFlowPurchasing = 'FBOFlowPurchasing',
  FBOFlowSales = 'FBOFlowSales',
  FBOClose = 'FBOClose',
  FBOCloseSmall = 'FBOCloseSmall',
  FBOCompany = 'FBOCompany',
  FBOCycle = 'FBOCycle',
  UpwardCarat = 'UpwardCarat',
  FBOLock = 'FBOLock',
  FBOUnlock = 'FBOUnlock',

  FBOPhotoPicture = 'FBOPhotoPicture',
  FBOPicking = 'FBOPicking',
  FBOShipping = 'FBOShipping',
  FBOLeavePage = 'FBOLeavePage',
  FBOInStock = 'FBOInStock',
  FBONoStock = 'FBONoStock',
  FBOPartialStock = 'FBOPartialStock',
  FBOPrint = 'FBOPrint',
  FBOEmail = 'FBOEmail',
  FBOExcel = 'FBOExcel',
  FBOFullscreen = 'FBOFullscreen',
  FBOFullscreenContract = 'FBOFullscreenContract',
  General = 'General',
  Help = 'Help',
  Integrations = 'Integrations',
  MenuRemoteSupport = 'MenuRemoteSupport',
  IconFlashFilledLightning = 'IconFlashFilledLightning',
  IconKebab = 'IconKebab',
  IconArrowUp = 'IconArrowUp',
  IconArrowDown = 'IconArrowDown',
  IconAvatar = 'IconAvatar',
  IconCaratFilled = 'IconCaratFilled',
  IconCaratRight = 'IconCaratRight',
  IconClose = 'IconClose',
  IconCopy = 'IconCopy',
  IconEdit = 'IconEdit',
  IconLinkHorizontal = 'IconLinkHorizontal',
  IconLocation = 'IconLocation',
  IconNote = 'IconNote',
  IconRefund = 'IconRefund',
  IconSave = 'IconSave',
  IconSales = 'IconSales',
  IconSplit = 'IconSplit',
  Info = 'Info',
  ImagePhotoPicture = 'ImagePhotoPicture',
  Inventory = 'Inventory',
  Items = 'Items',
  Leave = 'Leave',
  LeavePage = 'LeavePage',
  LightBulb = 'LightBulb',
  List = 'List',
  Location = 'Location',
  LogoMark = 'LogoMark',
  LogoWhite = 'LogoWhite',
  LogoWithText = 'LogoWithText',
  MagnifyingGlass = 'MagnifyingGlass',
  MaintenanceTools = 'MaintenanceTools',
  Manufacturing = 'Manufacturing',
  MenuSandbox = 'MenuSandbox',
  MenuHome = 'HomeIcon',
  MenuMaterials = 'MaterialsIcon',
  MenuSales = 'SalesIcon',
  MenuPurchasing = 'PurchasingIcon',
  MenuAccounting = 'AccountingIcon',
  MenuManufacturing = 'MenuManufacturing',
  MenuReports = 'ReportsIcon',
  MenuCommerce = 'CommerceIcon',
  MenuIntegrations = 'IntegrationsIcon',
  MenuHelp = 'MenuHelp',
  MenuSettings = 'MenuSettings',
  Materials = 'Materials',
  Measurements = 'Measurements',
  Menu = 'Menu',
  MiscSale = 'MiscSale',
  Mode = 'Mode',
  Monitor = 'Monitor',
  MoreVertical = 'MoreVertical',
  Move = 'Move',
  FBOMove = 'FBOMove',
  Pause = 'Pause',
  PDF = 'PDF',
  PhotoPlaceHolder = 'PhotoPlaceHolder',
  PinChecked = 'PinChecked',
  Place = 'Place',
  PlayArrow = 'PlayArrow',
  Print = 'Print',
  Printing = 'Printing',
  Purchase = 'Purchase',
  PurchaseOutline = 'PurchaseOutline',
  RecentlyUsed = 'RecentlyUsed',
  Refresh = 'Refresh',
  FBORefresh = 'FBORefresh',
  Refund = 'Refund',
  Repeat = 'Repeat',
  Reports = 'Reports',
  Sales = 'Sales',
  SalesOutline = 'SalesOutline',
  Save = 'Save',
  Scale = 'Scale',
  Security = 'Security',
  Setup = 'Setup',
  ShippingQuote = 'ShippingQuote',
  SubCircle = 'SubCircle',
  FBOSubCircle = 'FBOSubCircle',
  TrackingAim = 'TrackingAim',
  TransferOrder = 'TransferOrder',
  TrashCan = 'TrashCan',
  Tune = 'Tune',
  Upload = 'Upload',
  User = 'User',
  Warning = 'Warning',
  Widgets = 'Widgets',
  FBOWarningCircle = 'FBOWarningCircle',
  FBORightCircle = 'FBORightCircle',
  FBOWarehouse = 'FBOWarehouse',
  FBOCircleCheck = 'FBOCircleCheck',
  FBOEdit = 'FBOEdit',
  FBOPdf = 'FBOPdf',
  QuickBook = 'QuickBook',
  Xero = 'Xero',
  FBOQuickTransfer = 'FBOQuickTransfer',

  FBOImport = 'FBOImport',
  FBOReport = 'FBOReport',
  FBORecycleSmall = 'FBORecycleSmall',
  FBODeleteSmall = 'FBODeleteSmall',
  FBODuplicateSmall = 'FBODuplicateSmall',
  FBOReturnSmall = 'FBOReturnSmall',
  FBODocumentSmall = 'FBODocumentSmall',
  FBOReportSmall = 'FBOReportSmall',
  FBOCloseCircleSmall = 'FBOCloseCircleSmall',
  FBOAddCircleSmall = 'FBOAddCircleSmall',
  FBOEmailSmall = 'FBOEmailSmall',
  FBOCircleCheckedSmall = 'FBOCircleCheckedSmall',
  FBOLockedSmall = 'FBOLockedSmall',
  FBOPauseCircleSmall = 'FBOPauseCircleSmall',
  FBOPlayCircleSmall = 'FBOPlayCircleSmall',
  FBOStopCircleSmall = 'FBOStopCircleSmall',
  FBORedoSmall = 'FBORedoSmall',
  FBOQuestionMarkInfo = 'FBOQuestionMarkInfo',
  FBOArrowUp = 'FBOArrowUp',
  FBOArrowDown = 'FBOArrowDown',
  FBOFileDocument = 'FBOFileDocument',
  FBODocumentApprovalSmall = 'FBODocumentApprovalSmall',
}

export const icons = Object.freeze({
  [IconNames.Accounting]: Accounting,
  [IconNames.Add]: Add,
  [IconNames.AddCircle]: AddCircle,
  [IconNames.FBOAddCircle]: FBOAddCircle,
  [IconNames.AddNew]: AddNew,
  [IconNames.Analytics]: Analytics,
  [IconNames.ArrowBack]: ArrowBack,
  [IconNames.ArrowCollapsed]: ArrowCollapsed,
  [IconNames.ArrowDownward]: ArrowDownward,
  [IconNames.ArrowExpanded]: ArrowExpanded,
  [IconNames.ArrowForward]: ArrowForward,
  [IconNames.ArrowUpward]: ArrowUpward,
  [IconNames.AssignmentDownload]: AssignmentDownload,
  [IconNames.AssignmentLate]: AssignmentLate,
  [IconNames.AssignmentLines]: AssignmentLines,
  [IconNames.AssignmentReturn]: AssignmentReturn,
  [IconNames.AttachMoney]: AttachMoney,
  [IconNames.Back]: Back,
  [IconNames.Bell]: Bell,
  [IconNames.BorderInner]: BorderInner,
  [IconNames.Box]: Box,
  [IconNames.Calendar]: Calendar,
  [IconNames.CaratUp]: CaratUp,
  [IconNames.Cart]: Cart,
  [IconNames.Circle]: Circle,
  [IconNames.CheckCircle]: CheckCircle,
  [IconNames.CycleCircle]: CycleCircle,
  [IconNames.DefaultCompanyLogo]: DefaultCompanyLogo,
  [IconNames.Close]: Close,
  [IconNames.CloseCircle]: CloseCircle,
  [IconNames.CloseTag]: CloseTag,
  [IconNames.Code]: Code,
  [IconNames.ConnectPlug]: ConnectPlug,
  [IconNames.Consignment]: Consignment,
  [IconNames.Copy]: Copy,
  [IconNames.CreditReturn]: CreditReturn,
  [IconNames.Dashboard]: Dashboard,
  [IconNames.Delete]: Delete,
  [IconNames.DoneAll]: DoneAll,
  [IconNames.Download]: Download,
  [IconNames.DownwardExpandArrow]: DownwardExpandArrow,
  [IconNames.Duplicate]: Duplicate,
  [IconNames.Edit]: Edit,
  [IconNames.Email]: Email,
  [IconNames.Eye]: Eye,
  [IconNames.EyeClosed]: EyeClosed,
  [IconNames.FBOUncheckedCircle]: FBOUncheckedCircle,
  [IconNames.FBOCheckedCircle]: FBOCheckedCircle,
  [IconNames.FBOIndeterminateCircle]: FBOIndeterminateCircle,
  [IconNames.FBOAccountMapping]: FBOAccountMapping,
  [IconNames.FBOCaratRight]: FBOCaratRight,
  [IconNames.FBOCaratDown]: FBOCaratDown,
  [IconNames.FBOCaratRightSmall]: FBOCaratRightSmall,
  [IconNames.FBOExport]: FBOExport,
  [IconNames.FBOFlowMaterials]: FBOFlowMaterials,
  [IconNames.FBOFlowOther]: FBOFlowOther,
  [IconNames.FBOFlowPurchasing]: FBOFlowPurchasing,
  [IconNames.FBOFlowSales]: FBOFlowSales,
  [IconNames.FBOClose]: FBOClose,
  [IconNames.FBOCloseSmall]: FBOCloseSmall,
  [IconNames.FBOCompany]: FBOCompany,
  [IconNames.FBOCycle]: FBOCycle,
  [IconNames.UpwardCarat]: UpwardCarat,
  [IconNames.FBOLock]: FBOLock,
  [IconNames.FBOUnlock]: FBOUnlock,

  [IconNames.FBOPhotoPicture]: FBOPhotoPicture,
  [IconNames.FBOPicking]: FBOPicking,
  [IconNames.FBOShipping]: FBOShipping,
  [IconNames.FBOLeavePage]: FBOLeavePage,
  [IconNames.FBOInStock]: FBOInStock,
  [IconNames.FBOPartialStock]: FBOPartialStock,
  [IconNames.FBONoStock]: FBONoStock,
  [IconNames.FBOEmail]: FBOEmail,
  [IconNames.FBOPrint]: FBOPrint,
  [IconNames.FBOExcel]: FBOExcel,
  [IconNames.FBOFullscreen]: FBOFullscreen,
  [IconNames.FBOFullscreenContract]: FBOFullscreenContract,
  [IconNames.General]: General,
  [IconNames.Help]: Help,
  [IconNames.IconFlashFilledLightning]: IconFlashFilledLightning,
  [IconNames.IconKebab]: IconKebab,
  [IconNames.IconArrowUp]: IconArrowUp,
  [IconNames.IconArrowDown]: IconArrowDown,
  [IconNames.IconAvatar]: IconAvatar,
  [IconNames.IconCaratFilled]: IconCaratFilled,
  [IconNames.IconCaratRight]: IconCaratRight,
  [IconNames.IconClose]: IconClose,
  [IconNames.IconCopy]: IconCopy,
  [IconNames.IconEdit]: IconEdit,
  [IconNames.IconLinkHorizontal]: IconLinkHorizontal,
  [IconNames.IconLocation]: IconLocation,
  [IconNames.IconNote]: IconNote,
  [IconNames.IconRefund]: IconRefund,
  [IconNames.IconSave]: IconSave,
  [IconNames.IconSales]: IconSales,
  [IconNames.IconSplit]: IconSplit,
  [IconNames.Info]: Info,
  [IconNames.ImagePhotoPicture]: ImagePhotoPicture,
  [IconNames.Integrations]: Integrations,
  [IconNames.Inventory]: Inventory,
  [IconNames.Items]: Items,
  [IconNames.Leave]: Leave,
  [IconNames.LeavePage]: LeavePage,
  [IconNames.LightBulb]: LightBulb,
  [IconNames.List]: List,
  [IconNames.Location]: Location,
  [IconNames.LogoMark]: LogoMark,
  [IconNames.LogoWhite]: LogoWhite,
  [IconNames.LogoWithText]: LogoWithText,
  [IconNames.MagnifyingGlass]: MagnifyingGlass,
  [IconNames.Manufacturing]: Manufacturing,
  [IconNames.MaintenanceTools]: MaintenanceTools,
  [IconNames.Materials]: Materials,
  [IconNames.Measurements]: Measurements,
  [IconNames.Menu]: Menu,
  [IconNames.MenuSandbox]: MenuSandbox,
  [IconNames.MenuHome]: MenuHome,
  [IconNames.MenuMaterials]: MenuMaterials,
  [IconNames.MenuSales]: MenuSales,
  [IconNames.MenuPurchasing]: MenuPurchasing,
  [IconNames.MenuAccounting]: MenuAccounting,
  [IconNames.MenuManufacturing]: MenuManufacturing,
  [IconNames.MenuReports]: MenuReports,
  [IconNames.MenuCommerce]: MenuCommerce,
  [IconNames.MenuIntegrations]: MenuIntegrations,
  [IconNames.MenuHelp]: MenuHelp,
  [IconNames.MenuRemoteSupport]: MenuRemoteSupport,
  [IconNames.MenuSettings]: MenuSettings,
  [IconNames.MiscSale]: MiscSale,
  [IconNames.Mode]: Mode,
  [IconNames.Monitor]: Monitor,
  [IconNames.MoreVertical]: MoreVertical,
  [IconNames.Move]: Move,
  [IconNames.FBOMove]: FBOMove,
  [IconNames.Pause]: Pause,
  [IconNames.PDF]: PDF,
  [IconNames.PhotoPlaceHolder]: PhotoPlaceHolder,
  [IconNames.PinChecked]: PinChecked,
  [IconNames.Place]: Place,
  [IconNames.PlayArrow]: PlayArrow,
  [IconNames.Print]: Print,
  [IconNames.Printing]: Printing,
  [IconNames.Purchase]: Purchase,
  [IconNames.PurchaseOutline]: PurchaseOutline,
  [IconNames.RecentlyUsed]: RecentlyUsed,
  [IconNames.Refresh]: Refresh,
  [IconNames.FBORefresh]: FBORefresh,
  [IconNames.Refund]: Refund,
  [IconNames.Repeat]: Repeat,
  [IconNames.Reports]: Reports,
  [IconNames.Sales]: Sales,
  [IconNames.SalesOutline]: SalesOutline,
  [IconNames.Save]: Save,
  [IconNames.Scale]: Scale,
  [IconNames.Security]: Security,
  [IconNames.Setup]: Setup,
  [IconNames.ShippingQuote]: ShippingQuote,
  [IconNames.SubCircle]: SubCircle,
  [IconNames.FBOSubCircle]: FBOSubCircle,
  [IconNames.TrackingAim]: TrackingAim,
  [IconNames.TrashCan]: TrashCan,
  [IconNames.Tune]: Tune,
  [IconNames.TransferOrder]: TransferOrder,
  [IconNames.Upload]: Upload,
  [IconNames.User]: User,
  [IconNames.Warning]: Warning,
  [IconNames.Widgets]: Widgets,
  [IconNames.FBORightCircle]: FBORightCircle,
  [IconNames.FBOWarehouse]: FBOWarehouse,
  [IconNames.FBOWarningCircle]: FBOWarningCircle,
  [IconNames.FBOCircleCheck]: FBOCircleCheck,
  [IconNames.FBOEdit]: FBOEdit,
  [IconNames.FBOPdf]: FBOPdf,
  [IconNames.QuickBook]: QuickBook,
  [IconNames.Xero]: Xero,
  [IconNames.FBOQuickTransfer]: FBOQuickTransfer,

  [IconNames.FBOImport]: FBOImport,
  [IconNames.FBOReport]: FBOReport,
  [IconNames.FBORecycleSmall]: FBORecycleSmall,
  [IconNames.FBODeleteSmall]: FBODeleteSmall,
  [IconNames.FBODuplicateSmall]: FBODuplicateSmall,
  [IconNames.FBOReturnSmall]: FBOReturnSmall,
  [IconNames.FBODocumentSmall]: FBODocumentSmall,
  [IconNames.FBOCloseCircleSmall]: FBOCloseCircleSmall,
  [IconNames.FBOAddCircleSmall]: FBOAddCircleSmall,
  [IconNames.FBOEmailSmall]: FBOEmailSmall,
  [IconNames.FBOReportSmall]: FBOReportSmall,
  [IconNames.FBOCircleCheckedSmall]: FBOCircleCheckedSmall,
  [IconNames.FBOLockedSmall]: FBOLockedSmall,
  [IconNames.FBOPauseCircleSmall]: FBOPauseCircleSmall,
  [IconNames.FBOPlayCircleSmall]: FBOPlayCircleSmall,
  [IconNames.FBOStopCircleSmall]: FBOStopCircleSmall,
  [IconNames.FBORedoSmall]: FBORedoSmall,
  [IconNames.FBOQuestionMarkInfo]: FBOQuestionMarkInfo,
  [IconNames.FBOArrowUp]: FBOArrowUp,
  [IconNames.FBOArrowDown]: FBOArrowDown,
  [IconNames.FBOFileDocument]: FBOFileDocument,
  [IconNames.FBODocumentApprovalSmall]: FBODocumentApprovalSmall,
});
