import {
  createApiCall,
  DataWithPagination,
  paginatedApiCall,
} from 'services/api';
import { RequestConfig, defaultMaximumPagination } from 'helpers';

import { ShipCarton, ShipItem, Ship } from './types';
import { transformShip, transformToUpdateShip } from './transformations';

const expands = [
  'carrier',
  'shipBundleItems',
  'shipBundleItems.shipItems',
  'shipBundleItems.shipItems.item.images',
  'shipBundleItems.shipItems.item.itemTrackingTypes',
  'shipBundleItems.shipItems.uom',
  'carrierService',
  'shipCartons',
  'shipItems',
  'shipItems.item.images',
  'shipItems.item.itemTrackingTypes',
  'shipItems.salesOrderItem.saleItem.images',
  'shipItems.salesOrderItem.saleItem',
  'shipItems.uom',
  'salesOrder.customer.customerAddresses',
  'salesOrder.customer.carrierAccountSettings',
  'salesOrder.salesOrderItems.saleItem',
  'salesOrder.paymentTerm',
  'salesOrder.representative',
  'salesOrder.location.address',
];

const expandsWithoutCustomer = expands.filter(
  (expand: string) => !expand.includes('customer')
);

export const fetchShipsAPI = async (
  config: RequestConfig
): Promise<DataWithPagination<Ship>> => {
  const {
    pagination = defaultMaximumPagination,
    expands = [],
    customQuickSearchColumns = [],
    quickSearchValue,
  } = config;
  let path = `/v1/ships?expand=${expands.join(',')}`;

  if (quickSearchValue) {
    path += `&quickSearchColumns=${customQuickSearchColumns}&quickSearchValue=${encodeURIComponent(
      quickSearchValue
    )}`;
  }

  const res = await paginatedApiCall(path, pagination, transformShip);

  return res;
};

export const getShip = async (shipId: number): Promise<Ship> => {
  const ship = createApiCall({
    path: `/v1/ships/${shipId}?expand=${expandsWithoutCustomer.join(',')}`,
    method: 'GET',
  });
  const res = await ship();
  const data = res.data;

  return transformShip(data);
};

export const updateShip = async (ship: Ship): Promise<Ship> => {
  const response = await createApiCall(
    {
      path: `/v1/ships?expand=${expandsWithoutCustomer.join(',')}`,
      method: 'PUT',
      body: transformToUpdateShip(ship),
    },
    {
      getMessage: {
        success: () => 'Shipping saved',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return transformShip(response.data);
};

export const updateShipItem = async (
  shipId: number,
  shipItem: ShipItem
): Promise<void> => {
  createApiCall(
    {
      path: `/v1/ships/${shipId}/ship_items?expand=${expandsWithoutCustomer.join(
        ','
      )}`,
      method: 'PUT',
      body: [shipItem],
    },
    {
      getMessage: {
        success: () => 'Ship item successfully updated',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();
};

export const updateShipItemCarton = async (
  shipId: number,
  shipItems: ShipItem[],
  cartonId: number
) => {
  await createApiCall(
    {
      path: `/v1/ships/${shipId}/ship_items`,
      method: 'PATCH',
      body: shipItems.map((s) => ({
        id: s.id,
        shipCartonId: cartonId,
      })),
    },
    {
      getMessage: {
        success: () => 'Ship items successfully moved',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();
};

export const packShip = async (shipId: number): Promise<Ship> => {
  const response = await createApiCall(
    {
      path: `/v1/ships/${shipId}/pack`,
      method: 'POST',
    },
    {
      getMessage: {
        success: () => 'Shipping packed',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return transformShip(response.data);
};

export const unpackShip = async (shipId: number): Promise<Ship> => {
  const response = await createApiCall(
    {
      path: `/v1/ships/${shipId}/unpack`,
      method: 'POST',
    },
    {
      getMessage: {
        success: () => 'Shipping unpacked',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return transformShip(response.data);
};

export const finishShip = async (
  shipId: number,
  confirm: boolean = false
): Promise<Ship> => {
  const response = await createApiCall(
    {
      path: `/v1/ships/${shipId}/finish?${confirm ? 'confirm=true' : ''}`,
      method: 'POST',
    },
    {
      getMessage: {
        success: () => 'Shipping finished',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return transformShip(response.data);
};

export const voidShip = async (shipId: number): Promise<Ship> => {
  const response = await createApiCall(
    {
      path: `/v1/ships/${shipId}/void`,
      method: 'POST',
    },
    {
      getMessage: {
        success: () => 'Shipping opened',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return transformShip(response.data);
};

export const createShipCartons = async (
  shipId: number,
  cartons: ShipCarton[]
): Promise<Ship> => {
  const fetchShipCartons = createApiCall(
    {
      path: `/v1/ships/${shipId}/cartons?expand=${expandsWithoutCustomer.join(
        ','
      )}`,
      method: 'POST',
      body: cartons,
    },
    {
      getMessage: {
        success: () => 'New cartons created',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  );

  const response = await fetchShipCartons();
  return transformShip(response.data);
};

export const splitShipItem = async (
  shipId: number,
  shipItemId: number,
  quantityToSplitOff: number,
  destinationCartonId: number
): Promise<Ship> => {
  const response = await createApiCall(
    {
      path: `/v1/ships/${shipId}/ship_items/${shipItemId}/split`,
      method: 'POST',
      body: {
        quantityToSplitOff,
        destinationCartonId,
      },
    },
    {
      getMessage: {
        success: () => 'Ship item successfully split',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

  return transformShip(response.data);
};

export const deleteShipCarton = (
  shipId: number | null,
  cartonId: number | null
) =>
  createApiCall(
    {
      path: `/v1/ships/${shipId}/cartons/${cartonId}`,
      method: 'DELETE',
    },
    {
      getMessage: {
        success: () => 'Package successfully removed',
        error: (error: any) => `${error.response.data.message}`,
      },
    }
  )();

export const voidMultipleShips = async (ids: number[]): Promise<void> => {
  await createApiCall(
    {
      path: `/v1/ships/void`,
      method: 'POST',
      body: ids,
    },
    {
      getMessage: {
        success: (res: any) => res.data,
        error: (error: any) => error.response.data.message,
      },
    }
  )();
};
