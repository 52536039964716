import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';

import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import { TextField } from 'ui/components/TextField/TextField';
import { DatePickerWrapper } from 'ui/components/TextField/DatePickerWrapper';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { CustomersAutocomplete } from 'ui/components/Autocomplete/CustomerAutocomplete';
import { CarriersAutocomplete } from 'ui/components/Autocomplete/CarriersAutocomplete';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import { LocationsAsyncAutocomplete } from 'ui/components/Autocomplete/LocationsAsyncAutocomplete';

import {
  SalesOrder,
  SalesOrderItem,
  SalesOrderItemTypes,
  SalesOrderStatus,
} from 'services/salesOrders';
import { getSettingsSalesOrder } from 'services/settings/salesOrders';
import {
  CustomField,
  fetchCustomFieldsAPI,
  ObjectType,
  useCustomFields,
} from 'services/customFields';
import { showAlert } from 'services/alert/redux';
import { Location, LocationType } from 'services/locations';
import { Carrier, getCarriers } from 'services/carriers';
import { useHandleTextFieldChange } from 'services/forms';
import { Customer } from 'services/customers';
import {
  Address,
  AddressValidation,
  BillToShipToAddressBeta, // @beta: additionally added for testing
  initalValidAddress,
} from 'services/addresses';
import { TextFieldAddressBeta } from '../TextFieldAddressBeta';
import { getShippingIntegrationConnection } from 'services/integrations/shipping/redux';
import {
  getValidateAddress,
  ShippingConnectionType,
} from 'services/integrations/shipping';
import { AddressValidationModal } from 'ui/components/Modal/AddressModal/components';
import { showNotification } from 'services/api';

import { getExchangeRate, replaceValueInCollection } from 'helpers';
import { SalesOrderGeneralTabProps } from './types';
import {
  calculateSoCustomerFields,
  transformShipToAddress,
} from './transformations';
import { SalesOrderActiveIdState } from '../../types';
import { calculateSoItemPrice } from '../SalesOrderItems/transformations';
import {
  editSalesOrderPermissions,
  calculateMultiCurrencyFields,
  useDisableSalesOrderField,
} from '../../helpers';
import { useSalesOrderGeneralTabStyles } from './styled';
import {
  setShipToAsBillToAddress,
  setVerifiedBillToAddress,
  setVerifiedShipToAddress,
} from './helpers';
import { createCustomShipToActionItems } from './consts';
import { logErrorCtx } from 'app/logging';
import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import { themeRestyle } from 'ui/theme';
import FBOCustomFields from 'ui/components/CustomFields/CustomFields/FBOCustomFields';

const getLabelName = (option: any) => option.displayName || option.name;

const SalesOrderGeneralTab: React.FC<SalesOrderGeneralTabProps> = (props) => {
  const {
    salesOrder,
    setSalesOrder,
    validationErrors,
    customFieldsErrors,
    oldState,
    isLoading,
    setIsLoading,
  } = props;

  const classes = useSalesOrderGeneralTabStyles();
  const disableField = useDisableSalesOrderField(salesOrder.status);
  const connection = useSelector(
    getShippingIntegrationConnection(ShippingConnectionType.Shippo)
  );

  const [addressValidationModalVisible, setAddressValidationModalVisible] =
    useState<boolean>(false);
  const [addressValidation, setAddressValidation] =
    useState<AddressValidation>(initalValidAddress);

  const dispatch = useDispatch();

  const { items: carriers } = useSelector(getCarriers);
  const salesOrderSettings = useSelector(getSettingsSalesOrder);

  // Transformation
  const shipToAddressTransformed = transformShipToAddress(salesOrder);

  const setCustomFields = useCustomFields<SalesOrder>(setSalesOrder);

  const [showPricingRuleConfirmation, setShowPricingRuleConfirmation] =
    useState(false);

  const firstInputElement = useRef<HTMLInputElement>(null);

  const orderNumberDisabled = salesOrder.status === SalesOrderStatus.Issued;

  const editPermission = editSalesOrderPermissions(salesOrder);

  const selectedCarrier = useMemo(
    () => carriers.find((c) => c.id === salesOrder.carrierId) || null,
    [salesOrder.carrierId, carriers]
  );

  useEffect(() => {
    if (salesOrder.id !== null && firstInputElement.current !== null) {
      firstInputElement.current.focus();
    }
  }, [salesOrder.id]);

  useEffect(() => {
    // Fetch customFields and add them to state
    if (salesOrder.id === SalesOrderActiveIdState.New) {
      (async () => {
        const customFields = (
          await fetchCustomFieldsAPI({}, ObjectType.SalesOrder)
        ).data;
        if (oldState.current) {
          oldState.current.customFields = customFields;
        }
        setCustomFields(customFields);
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesOrder.id]);

  const handleTextFieldChange = useHandleTextFieldChange<SalesOrder>(
    setSalesOrder,
    salesOrder
  );

  const handleCustomerChange = (customer: Customer | null) => {
    setSalesOrder((old) =>
      calculateMultiCurrencyFields(
        calculateSoCustomerFields(old, customer, salesOrderSettings),
        getExchangeRate(customer)
      )
    );

    const hasSaleItems = salesOrder.salesOrderItemList.some(
      (item) =>
        item.salesOrderItemType === SalesOrderItemTypes.Sale ||
        item.salesOrderItemType === SalesOrderItemTypes.CreditReturn
    );

    // we want to show pricing rule confirmation modal if sales order has sale items or credit return AND customer is selected
    if (customer && hasSaleItems) {
      setShowPricingRuleConfirmation(true);
    }

    if (customer?.alertNotes) {
      dispatch(showAlert(customer.alertNotes));
    }
  };

  const handleDateChange = (name: string) => (value: any) => {
    const date = moment(value);

    setSalesOrder((old) => ({ ...old, [name]: !value ? null : date.toDate() }));
  };

  const handleLocationChange = useCallback(
    (location: Location | null) => {
      setSalesOrder((old) => ({
        ...old,
        locationId: location ? location.id : 0,
      }));
    },
    [setSalesOrder]
  );

  const customFieldChanged = useCallback(
    (customField: CustomField) => {
      const index = salesOrder.customFields.findIndex(
        (c) => c.id === customField.id
      );
      setCustomFields(
        (old) => replaceValueInCollection<CustomField>(old, customField, index)!
      );
    },
    [salesOrder.customFields, setCustomFields]
  );

  const handleCarrierChange = (value: Carrier | null) => {
    setSalesOrder((previousSalesOrder) => ({
      ...previousSalesOrder,
      carrierId: value ? value.id : null,
      carrierServiceId: null,
    }));
  };

  // Input change handlers
  const handleAutocompleteChange = useCallback(
    (name: string, defaultValue: any, value?: string) =>
      (e: React.ChangeEvent<{}>, v: any) => {
        setSalesOrder((previousSalesOrder) => ({
          ...previousSalesOrder,
          [name]: v ? (value ? v[value] : v) : defaultValue,
        }));
      },
    [setSalesOrder]
  );

  const selectedService = useMemo(() => {
    if (!selectedCarrier) {
      return null;
    }
    return (
      selectedCarrier.carrierServiceList.find(
        (s) => s.id === salesOrder.carrierServiceId
      ) || null
    );
  }, [salesOrder.carrierServiceId, selectedCarrier]);

  const handleConfirmPricingRuleChange = async () => {
    setIsLoading(true);

    // fetch new sale order items prices
    const getNewSoItem = async (soItem: SalesOrderItem) => {
      const { saleItem, quantity, salesOrderItemType, uomId } = soItem;

      const { price, hasPricingRule } = await calculateSoItemPrice(
        salesOrderItemType,
        saleItem,
        quantity,
        salesOrder.customerId,
        uomId
      );

      return { ...soItem, price, hasPricingRule };
    };

    const newSoItems: SalesOrderItem[] = [];

    for (let i = 0; i < salesOrder.salesOrderItemList.length; i++) {
      const newSoItem = await getNewSoItem(salesOrder.salesOrderItemList[i]);
      newSoItems.push(newSoItem);
    }

    setSalesOrder((old) => ({
      ...old,
      salesOrderItemList: newSoItems,
    }));

    setIsLoading(false);
    setShowPricingRuleConfirmation(false);
  };

  const billToAddress: BillToShipToAddressBeta = useMemo(() => {
    return {
      phone: salesOrder.billToPhone, // @beta: additionally added for testing
      email: salesOrder.billToEmail, // @beta: additionally added for testing
      name: salesOrder.billToName,
      street: salesOrder.billToStreet,
      street2: salesOrder.billToStreet2,
      city: salesOrder.billToCity,
      state: salesOrder.billToState,
      country: salesOrder.billToCountry,
      postalCode: salesOrder.billToPostalCode,
      companyName: salesOrder.billToCompanyName,
      residential: salesOrder.billToResidential,
      attention: salesOrder.billToAttention,
      verified: salesOrder.billToVerified,
    };
  }, [salesOrder]);

  const shipToAddress: BillToShipToAddressBeta = useMemo(() => {
    return {
      phone: salesOrder.shipToPhone, // @beta: additionally added for testing
      email: salesOrder.shipToEmail, // @beta: additionally added for testing
      name: salesOrder.shipToName,
      street: salesOrder.shipToStreet,
      street2: salesOrder.shipToStreet2,
      city: salesOrder.shipToCity,
      state: salesOrder.shipToState,
      country: salesOrder.shipToCountry,
      postalCode: salesOrder.shipToPostalCode,
      companyName: salesOrder.shipToCompanyName,
      residential: salesOrder.shipToResidential,
      attention: salesOrder.shipToAttention,
      verified: salesOrder.shipToVerified,
    };
  }, [salesOrder]);

  const handleAddressValidationModalClose = useCallback(
    () => setAddressValidationModalVisible(false),
    []
  );

  const handleVerifyClick = useCallback(async () => {
    if (!connection) {
      return;
    }

    setIsLoading(true);

    try {
      const validatedAddress = await getValidateAddress(
        connection,
        shipToAddressTransformed
      );

      if (!shipToAddress.verified) {
        setAddressValidationModalVisible(true);
      }

      setAddressValidation(validatedAddress);
    } catch (error) {
      logErrorCtx('Error in handleVerifyClick', {
        error: error as Error,
        stackTrace: (error as Error).stack,
        component: 'SalesOrderGeneralTabBeta SalesOrderGeneralTab',
        title: 'Error in handleVerifyClick',
        description: 'Error in handleVerifyClick',
      });
    }

    setIsLoading(false);
  }, [
    shipToAddressTransformed,
    shipToAddress.verified,
    connection,
    setIsLoading,
  ]);

  const handleBillToChanged = useCallback(
    (adr: BillToShipToAddressBeta) => {
      setSalesOrder((oldSalesOrder) => {
        return {
          ...oldSalesOrder,
          billToPhone: adr.phone, // @beta: additionally added for testing
          billToEmail: adr.email, // @beta: additionally added for testing
          billToCity: adr.city,
          billToStreet: adr.street,
          billToStreet2: adr.street2,
          billToState: adr.state,
          billToPostalCode: adr.postalCode,
          billToName: adr.name,
          billToCountry: adr.country,
          billToCompanyName: adr.companyName,
          billToResidential: adr.residential,
          billToAttention: adr.attention,
          billToVerified: adr.verified,
        };
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [salesOrder, setSalesOrder]
  );

  const handleShipToChanged = useCallback(
    (adr: BillToShipToAddressBeta) => {
      setSalesOrder((oldSalesOrder) => {
        return {
          ...oldSalesOrder,
          shipToEmail: adr.email, // @beta: additionally added for testing
          shipToPhone: adr.phone, // @beta: additionally added for testing
          shipToCity: adr.city,
          shipToStreet: adr.street,
          shipToStreet2: adr.street2,
          shipToState: adr.state,
          shipToPostalCode: adr.postalCode,
          shipToName: adr.name,
          shipToCountry: adr.country,
          shipToCompanyName: adr.companyName,
          shipToResidential: adr.residential,
          shipToAttention: adr.attention,
          shipToVerified: adr.verified,
        };
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [salesOrder, setSalesOrder]
  );

  const addressChoices: Address[] = useMemo(() => {
    const selectedCustomer = salesOrder.customer;

    return selectedCustomer?.addresses || [];
  }, [salesOrder]);

  const onSaveVerified = useCallback(() => {
    setIsLoading(true);

    if (_.isEqual(billToAddress, shipToAddress)) {
      setVerifiedShipToAddress(addressValidation, setSalesOrder);

      setVerifiedBillToAddress(addressValidation, setSalesOrder);

      setAddressValidationModalVisible(false);

      showNotification('Bill to and ship to address validated', {
        variant: 'success',
      });

      setIsLoading(false);
      return;
    }

    setVerifiedShipToAddress(addressValidation, setSalesOrder);

    setAddressValidationModalVisible(false);

    showNotification('Ship to address validated', { variant: 'success' });

    setIsLoading(false);
  }, [
    setSalesOrder,
    addressValidation,
    setIsLoading,
    billToAddress,
    shipToAddress,
  ]);

  const setShippingSameAsBilling = useCallback(() => {
    setShipToAsBillToAddress(setSalesOrder);
  }, [setSalesOrder]);

  const customShipToActionItems = useMemo(
    () =>
      createCustomShipToActionItems(
        setShippingSameAsBilling,
        salesOrder.status
      ),
    [setShippingSameAsBilling, salesOrder.status]
  );

  const verifiedButtonVisible =
    !!connection && shipToAddress.name && shipToAddress.country === 'US';

  return (
    <>
      <Grid
        sx={{
          overflowY: 'scroll',
          maxHeight: '30%',
          padding: '16px',
        }}
        container
        columnSpacing="16px"
        columns={16}
        disableEqualOverflow
      >
        <Grid xs={4}>
          <CustomersAutocomplete
            onChange={handleCustomerChange}
            value={salesOrder.customer}
            placeholder="Select customer"
            label="Customer"
            required
            disabled={disableField('customer') || orderNumberDisabled}
            additionalInputProps={{ inputRef: firstInputElement }}
            permissions={editPermission}
            error={!!validationErrors.customerId}
            expands={[
              'customerAddresses.customerAddressContacts',
              'tags',
              'carrierAccountSettings',
              'currency',
            ]}
            dataQa="sale-order-customer"
          />
        </Grid>
        <Grid xs={4}>
          <TextField
            className="redesign"
            variant="standard"
            type="text"
            label="Order No."
            placeholder="Enter number"
            name="number"
            value={salesOrder.number}
            fullWidth
            required
            onChange={handleTextFieldChange}
            error={!!validationErrors.number}
            dataQa="sale-order-order-no"
            readOnly={orderNumberDisabled}
            disabled={disableField('customerPoNumber')}
          />
        </Grid>
        <Grid xs={4}>
          <LocationsAsyncAutocomplete
            label="Location"
            permissions={editPermission}
            onChange={handleLocationChange}
            value={salesOrder ? salesOrder.locationId : null}
            error={!!validationErrors.locationId}
            companyWide={false}
            required
            disabled={disableField('location')}
            dataQa="sale-order-location"
            parentId={null}
            locationTypes={[
              LocationType.Stock,
              LocationType.Receiving,
              LocationType.Shipping,
            ]}
          />
        </Grid>
        <Grid xs={2}>
          <TextField
            className="redesign"
            variant="standard"
            type="text"
            label="Customer PO"
            placeholder="Customer PO"
            name="customerPONumber"
            value={salesOrder.customerPONumber}
            permissions={editPermission}
            disabled={disableField('customerPoNumber')}
            fullWidth
            onChange={handleTextFieldChange}
            error={!!validationErrors.customerPONumber}
            helperText={validationErrors.customerPONumber}
            dataQa="sale-order-customer-po-number"
          />
        </Grid>
        <Grid xs={2}>
          <TextField
            className="redesign"
            variant="standard"
            type="text"
            label="Vendor PO"
            placeholder="Vendor PO"
            name="vendorPONumber"
            value={salesOrder.vendorPONumber}
            permissions={editPermission}
            disabled={disableField('vendorPoNumber')}
            fullWidth
            onChange={handleTextFieldChange}
            error={!!validationErrors.vendorPONumber}
            helperText={validationErrors.vendorPONumber}
            dataQa="sale-order-vendor-po-number"
          />
        </Grid>
        <Grid xs={8}>
          <DatePickerWrapper
            className={`redesign ${classes.fullWidth}`}
            value={salesOrder.dateScheduled}
            label="Scheduled"
            name="dateScheduled"
            permissions={editPermission}
            disabled={disableField('dateScheduled')}
            onChange={handleDateChange('dateScheduled')}
            error={!!validationErrors.dateScheduled}
            helperText={validationErrors.dateScheduled}
          />
        </Grid>
        <Grid xs={4}>
          <CarriersAutocomplete
            label="Carrier"
            name="carrierId"
            value={selectedCarrier}
            placeholder="Select carrier"
            onChange={handleCarrierChange}
            disabled={disableField('carrier')}
            permissions={editPermission}
            error={!!validationErrors.carrierId}
            helperText={validationErrors.carrierId}
          />
        </Grid>
        <Grid xs={4}>
          <Autocomplete
            label="Service"
            name="service"
            value={selectedService}
            placeholder="Select service"
            options={selectedCarrier ? selectedCarrier.carrierServiceList : []}
            permissions={editPermission}
            disabled={disableField('service')}
            getOptionLabel={getLabelName}
            onChange={handleAutocompleteChange('carrierServiceId', null, 'id')}
            error={!!validationErrors.carrierId}
            helperText={validationErrors.carrierId}
          />
        </Grid>
        <Grid xs={8}>
          <TextFieldAddressBeta
            label="Bill To"
            value={billToAddress}
            addressChoices={addressChoices}
            permissions={editPermission}
            disabled={disableField('billTo')}
            placeholder="Select bill to address"
            onChange={handleBillToChanged}
            customerId={salesOrder.customerId}
            salesOrder={salesOrder}
            setSalesOrder={setSalesOrder}
          />
        </Grid>
        <Grid xs={verifiedButtonVisible ? 7 : 8}>
          <TextFieldAddressBeta
            label="Ship To"
            value={shipToAddress}
            addressChoices={addressChoices}
            placeholder="Select ship to address"
            disabled={disableField('shipTo')}
            permissions={editPermission}
            onChange={handleShipToChanged}
            customerId={salesOrder.customerId}
            additionalActionItems={customShipToActionItems}
            salesOrder={salesOrder}
            setSalesOrder={setSalesOrder}
          />
        </Grid>
        {verifiedButtonVisible && (
          <Grid
            xs={1}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <>
              {!shipToAddress.verified ? (
                <FBOButton
                  variant="secondary"
                  color="positive"
                  size="medium"
                  onClick={handleVerifyClick}
                  disabled={isLoading}
                  fullWidth
                  data-qa="so-general-verify-address"
                >
                  Verify
                </FBOButton>
              ) : (
                <VerifiedUserIcon sx={{ color: 'green' }} />
              )}
            </>
          </Grid>
        )}
        <Grid container xs={16} mb={themeRestyle.spacing(6)}>
          <FBOCustomFields
            customFields={salesOrder.customFields}
            onFieldChange={customFieldChanged}
            permissions={editPermission}
            disabled={disableField('customFields')}
            errors={customFieldsErrors}
          />
        </Grid>
      </Grid>
      <ConfirmationModal
        open={showPricingRuleConfirmation}
        title="Customer change"
        body="Apply item pricing based on pricing rules?"
        onCancelClicked={() => setShowPricingRuleConfirmation(false)}
        onConfirmClicked={handleConfirmPricingRuleChange}
        isLoading={isLoading}
        confirmLabel="Apply"
        cancelLabel="Ignore"
        confirmButtonRed
      />
      <AddressValidationModal
        onClose={handleAddressValidationModalClose}
        modalVisible={addressValidationModalVisible}
        addressValidation={addressValidation}
        onSaveOriginal={handleAddressValidationModalClose}
        onSaveVerified={onSaveVerified}
      />
    </>
  );
};

export default memo(SalesOrderGeneralTab);
