import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Routes } from '../../navigation';
import { getActiveUser } from 'services/user';
import _ from 'lodash';

import { DiscoverChannelsPageCmp } from './types';
import { DiscoverChannelsHeroComponent } from './components/DiscoverChannelsHeroComponent';
import { CreateAccountModal } from './components/CreateAccountModal';
import { SuccessModal } from './components/SuccessModal';
import { getSellwareUrl } from './helpers';
import { useFlags } from 'helpers/useFlags';
import { register, reconnect } from 'services/commerce/api';
import {
  fetchSellwareActiveAccount,
  getCommerceAccountIsConfigured,
  getCommerceAccountIsDisconnected,
  getCommerceAccountIsOrWasConnected,
} from 'services/commerce';
import { GlobalSettingsBanner } from '../../components/GlobalSettingsBanner';
import { ReconnectBanner } from '../../components/ReconnectBanner';
import { ReconnectingModal } from './components/ReconnectingModal';
import { showNotification } from 'services/api';
import { getSettingsCompany } from 'services/settings/company';

const DiscoverChannelsPage: DiscoverChannelsPageCmp = (props: any) => {
  const [createAccountModalOpen, setCreateAccountModalOpen] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [reconnectingModalOpen, setReconnectingModalOpen] = useState(false);
  const [sellwareUrlForRegisterFlow, setSellwareUrlForRegisterFlow] =
    useState<string>('');
  const [sellwareUrlForReconnectFlow, setSellwareUrlForReconnectFlow] =
    useState<string>('');

  const flags = useFlags();

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const isOrWasConnectedToSellware = useSelector(
    getCommerceAccountIsOrWasConnected
  );

  const isSellwareConfigured = useSelector(getCommerceAccountIsConfigured);

  const isSellwareDisconnected = useSelector(getCommerceAccountIsDisconnected);

  const activeUser = useSelector(getActiveUser);

  const companySettings = useSelector(getSettingsCompany);
  const companyName = _.get(companySettings, 'name', '');

  const showReconnectBanner =
    isSellwareDisconnected && isOrWasConnectedToSellware;

  const showGlobalSettingsBanner =
    !isSellwareConfigured && isOrWasConnectedToSellware && !showReconnectBanner;

  useEffect(() => {
    const registrationUrl =
      (flags.commerceAuth0Enabled
        ? process.env.REACT_APP_COMMERCE_REGISTER_URL
        : process.env.REACT_APP_SELLWARE_REGISTER_URL) || '';

    const getSellwareUrlForRegisterFlow = async () => {
      const url = await getSellwareUrl(
        activeUser,
        companyName,
        true,
        undefined,
        registrationUrl
      );
      setSellwareUrlForRegisterFlow(url);
    };

    const getSellwareUrlForReconnectFlow = async () => {
      const url = await getSellwareUrl(
        activeUser,
        companyName,
        false,
        true,
        registrationUrl
      );
      setSellwareUrlForReconnectFlow(url);
    };

    if (showReconnectBanner && activeUser && companyName) {
      getSellwareUrlForReconnectFlow();
    } else if (!isOrWasConnectedToSellware && activeUser && companyName) {
      getSellwareUrlForRegisterFlow();
    }
  }, [activeUser, showReconnectBanner, companyName, flags]);

  useEffect(() => {
    const callRegistration = async () => {
      if (props.location.search) {
        const queryParams = new URLSearchParams(location.search);

        const tenantId = queryParams?.get('tenantId');
        const appId = queryParams?.get('appId');
        const apiSecret = queryParams?.get('apiSecret');
        const registerFlow = queryParams?.get('register');

        if (tenantId && appId && apiSecret && registerFlow) {
          history.replace({
            search: '',
          });
          setCreateAccountModalOpen(true);

          try {
            await register(tenantId, appId, apiSecret);

            setSuccessModalOpen(true);
            setCreateAccountModalOpen(false);
            setTimeout(() => {
              // @ts-ignore
              dispatch(fetchSellwareActiveAccount());
            }, 1000);
          } catch (e) {
            setCreateAccountModalOpen(false);
            showNotification(
              'There was an error connecting to Fishbowl Commerce. Try again later.',
              {
                variant: 'error',
              }
            );
          }
        }
      }
    };

    if (location.search) {
      callRegistration();
    }
  }, []);

  useEffect(() => {
    const callReconnect = async () => {
      const queryParams = new URLSearchParams(location.search);

      const tenantId = queryParams?.get('tenantId');
      const appId = queryParams?.get('appId');
      const apiSecret = queryParams?.get('apiSecret');
      const reconnectFlow = queryParams?.get('reconnect');

      if (tenantId && appId && apiSecret && reconnectFlow) {
        history.replace({
          search: '',
        });
        setReconnectingModalOpen(true);

        try {
          await reconnect(tenantId, appId, apiSecret);

          setReconnectingModalOpen(false);

          setTimeout(() => {
            // @ts-ignore
            dispatch(fetchSellwareActiveAccount());
          }, 1000);

          showNotification(
            'Your Fishbowl Commerce account has been reconnected.',
            {
              variant: 'success',
            }
          );
        } catch (e) {
          setReconnectingModalOpen(false);

          showNotification(
            'There was an error reconnecting to Fishbowl Commerce. Try again later.',
            {
              variant: 'error',
            }
          );
        }
      }
    };

    if (location.search) {
      callReconnect();
    }
  }, []);

  const handleCloseSuccessModal = () => {
    setSuccessModalOpen(false);
  };

  return (
    <>
      {showReconnectBanner && (
        <ReconnectBanner sellwareUrl={sellwareUrlForReconnectFlow} />
      )}
      {showGlobalSettingsBanner && <GlobalSettingsBanner />}
      <DiscoverChannelsHeroComponent sellwareUrl={sellwareUrlForRegisterFlow} />
      <CreateAccountModal open={createAccountModalOpen} />
      <SuccessModal
        open={successModalOpen}
        handleClose={handleCloseSuccessModal}
      />
      <ReconnectingModal open={reconnectingModalOpen} />
    </>
  );
};

DiscoverChannelsPage.route = Routes.DiscoverChannelsPage;

export default DiscoverChannelsPage;
