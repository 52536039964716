import React, { useState, useEffect, memo } from 'react';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { TextField, Link } from '@mui/material';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useSelector } from 'react-redux';
import { COUNTRIES } from 'ui/components/Autocomplete/CountryAutocomplete';
import { getSettingsCompanyCountry } from 'services/settings/company';
import moment from 'moment';
import { activeUserHasPermission } from 'services/user/redux';
import { DatePickerWrapperProps } from './types';
import 'moment/locale/en-au';
import clsx from 'clsx';
import { IconNames, colorPalette } from 'ui/theme';
import { Icon } from 'ui/components/Icon';
import { ClickAwayListener } from '@material-ui/core';

const DatePickerWrapper: React.FC<DatePickerWrapperProps> = (props) => {
  const {
    permissions = [],
    disabled,
    value,
    error,
    helperText,
    ...otherProps
  } = props;

  const [countryLocale, setCountryLocale] = useState<String>('en-us');
  const canEdit = useSelector(activeUserHasPermission(permissions));
  const companyCountry = useSelector(getSettingsCompanyCountry);
  const [open, setOpen] = useState(false);

  const updateLocale = () => {
    const country = COUNTRIES.find((c) => c.code === companyCountry);
    let countryLocale = 'en-us'; // Default to en-us

    if (country?.locale?.toLowerCase() !== 'en-us') {
      countryLocale = 'en-au';
      moment.locale('en-au');
    } else {
      moment.locale('en-us');
    }

    setCountryLocale(countryLocale);
  };
  useEffect(updateLocale, [companyCountry]);

  const isDisabled = disabled || !canEdit;

  const onChangeUTC = (date: any) => {
    if (props.onChange) {
      props.onChange(date ? date : null);
    }
    setOpen(false);
  };
  const handleClickAway = () => {
    if (open) {
      setOpen(false);
    }
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div>
        <LocalizationProvider
          dateAdapter={AdapterMoment}
          adapterLocale={countryLocale}
        >
          <DatePicker
            {...otherProps}
            disabled={isDisabled}
            onChange={onChangeUTC}
            value={value}
            open={open}
            className={clsx({
              redesign: true,
              'no-margin': props.noMargin,
              'date-picker-wrapper': true,
            })}
            components={{
              OpenPickerIcon: () => (
                <Link
                  color="textPrimary"
                  underline="none"
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  <Icon
                    name={IconNames.Calendar}
                    sx={{
                      color: `${colorPalette.redesign.contentPrimary}`,
                      fontSize: 5,
                      opacity: () => (otherProps.disabled ? 0.6 : 1),
                    }}
                  />
                </Link>
              ),
            }}
            renderInput={(params) => (
              <TextField
                variant={'standard'}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                size="small"
                {...params}
                data-qa="date-custom-field"
                error={error}
                helperText={helperText}
              />
            )}
          />
        </LocalizationProvider>
      </div>
    </ClickAwayListener>
  );
};

export default memo(DatePickerWrapper);
