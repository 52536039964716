import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import {
  RadioGroup,
  Box,
  Grid,
  FormControlLabel,
  Typography,
  Radio,
  Checkbox,
  InputAdornment,
} from '@mui/material';
import _ from 'lodash';
import { useSelector } from 'react-redux';

import {
  PaymentTerm,
  PaymentTermType,
  initialPaymentTerm,
  fetchPaymentById,
  updatePaymentTerm,
  createPaymentTerm,
  deletePaymentTerm,
  restorePaymentTerm,
} from 'services/paymentTerms';
import { useUrlQueryObject } from 'services/url';
import { useHandleTextFieldChange } from 'services/forms';
import { TextField } from 'ui/components/TextField/TextField';
import { ConfirmationModal } from 'ui/components/Modal/ConfirmationModal';
import { DetailsCard } from 'ui/components/Page/DetailsCard';
import { activeUserHasPermission } from 'services/user/redux';
import { Errors, validateYup } from 'services/forms/validation';
import { PaymentTermsDetailsCardProps } from './types';
import { usePaymentTermsStyle } from './styled';
import { getPaymentTermValidation } from './validations';
import { PaymentTermTitleBar } from '../PaymentTermsTitleBar';
import { editPaymenTermPermissions } from '../helpers';

const PaymentTermsDetailsCard: React.FC<PaymentTermsDetailsCardProps> = (
  props
) => {
  const { activePaymentTermId, onClose, fetchSearchResult } = props;

  const classes = usePaymentTermsStyle(props);

  const [, setQueryParams] = useUrlQueryObject();

  const [activePayment, setActivePayment] =
    useState<PaymentTerm>(initialPaymentTerm);

  const [applyDiscountSelected, setApplyDiscountSelected] =
    useState<boolean>(false);

  const [errors, setErrors] = useState<Errors>({});

  const [deleteModalVisible, setDeleteModalVisible] =
    React.useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const oldState = useRef<PaymentTerm | null>(initialPaymentTerm);

  const firstInputElement = useRef<HTMLInputElement>(null);

  const editPermission = editPaymenTermPermissions(activePayment);

  const canClick = useSelector(activeUserHasPermission(editPermission));

  const isNetTermSelected = useMemo(
    () => activePayment.paymentTermType === PaymentTermType.Standard,
    [activePayment.paymentTermType]
  );

  useEffect(() => {
    if (activePayment.id !== null && firstInputElement.current !== null) {
      firstInputElement.current.focus();
    }
  }, [activePayment.id]);

  useEffect(() => {
    const asyncFc = async (id: number) => {
      setIsLoading(true);
      const payment = await fetchPaymentById(id);
      if (payment.discountDays || payment.discountPercent) {
        setApplyDiscountSelected(true);
      }
      oldState.current = payment;
      setActivePayment(payment);
      setIsLoading(false);
    };

    if (!activePaymentTermId) {
      setIsLoading(false);
      return;
    }

    if (activePaymentTermId < 0) {
      const newPaymentTerm: PaymentTerm = {
        ...initialPaymentTerm,
        paymentTermType: PaymentTermType.Standard,
        id: activePaymentTermId,
      };
      oldState.current = newPaymentTerm;
      setActivePayment(newPaymentTerm);
      setIsLoading(false);
      return;
    }

    setErrors({});
    asyncFc(activePaymentTermId);
  }, [activePaymentTermId]);

  const handleTextFieldChange = useHandleTextFieldChange<PaymentTerm>(
    setActivePayment,
    activePayment
  );

  const handleTextInputChangedWithValidation = (e: any) => {
    handleTextFieldChange(e);
  };

  const handleChangeType = (
    e: React.ChangeEvent<HTMLInputElement>,
    value: string
  ) => {
    // Reset form values and validations covered in Radio groups
    setActivePayment((old) => ({
      ...old,
      paymentTermType: value as PaymentTermType,
      dueDays: null,
      graceDays: null,
      dayOfMonthDue: null,
    }));
    setApplyDiscountSelected(false);
    setErrors({
      name: errors.name,
      dueDays: errors.dueDays,
      dayOfMonthDue: errors.dayOfMonthDue,
      graceDays: errors.graceDays,
    });
  };

  const handleApplyDiscount = (e: any) => {
    setApplyDiscountSelected(e.target.checked);
    setActivePayment((old) => ({
      ...old,
      discountDays: null,
      discountPercent: null,
    }));
    // Remove validation errors from fields
    setErrors(_.omit(errors, ['discountPercent', 'discountDays']));
  };

  const handleSave = useCallback(
    (close: boolean = false) =>
      async () => {
        const isValid = validateYup(
          activePayment,
          getPaymentTermValidation(applyDiscountSelected, isNetTermSelected),
          setErrors
        );

        if (!isValid || !activePayment.id) {
          return false;
        }

        setIsLoading(true);

        // CREATE PAYMENT TERM
        if (activePayment.id < 0) {
          try {
            const newActivePayment = await createPaymentTerm(activePayment);
            oldState.current = newActivePayment;
            setActivePayment(newActivePayment);
            await fetchSearchResult();
            setQueryParams({ activeId: newActivePayment.id });
            if (close) {
              setQueryParams({ activeId: null });
              onClose();
              return true;
            }
          } catch {
            setIsLoading(false);
            return false;
          }
          setIsLoading(false);
          return true;
        }

        // UPDATE PAYMENT TERM
        try {
          const newActivePayment = await updatePaymentTerm(activePayment);
          oldState.current = newActivePayment;
          setActivePayment(newActivePayment);
          await fetchSearchResult();
        } catch {
          setIsLoading(false);
          return false;
        }
        if (close) {
          onClose();
        }

        setIsLoading(false);
        return true;
      },
    [
      activePayment,
      applyDiscountSelected,
      fetchSearchResult,
      onClose,
      setQueryParams,
      isNetTermSelected,
    ]
  );

  const handleDeleteModalVisible = (visible: boolean) => () =>
    setDeleteModalVisible(visible);

  const handleDeleteConfirm = async () => {
    try {
      await deletePaymentTerm(activePayment.id!);
      await fetchSearchResult();
    } catch {
      return;
    }

    setDeleteModalVisible(false);
    onClose();
  };

  const handleUndeleteClicked = useCallback(
    async (close: boolean = false) => {
      setIsLoading(true);

      try {
        await restorePaymentTerm(activePaymentTermId!);
        const restoredPaymenTerm = await fetchPaymentById(activePaymentTermId!);
        oldState.current = restoredPaymenTerm;
        setActivePayment(restoredPaymenTerm);
      } catch {
        setIsLoading(false);
        return false;
      }

      if (close) {
        onClose();
      }

      setIsLoading(false);
      fetchSearchResult();
      return true;
    },

    [fetchSearchResult, onClose, activePaymentTermId]
  );

  return (
    <DetailsCard
      onSubmit={handleSave(false)}
      isLoading={isLoading}
      state={activePayment}
      oldState={oldState}
    >
      <PaymentTermTitleBar
        activePaymentTerm={activePayment}
        onSave={handleSave()}
        onClose={onClose}
        onUndeleteClicked={handleUndeleteClicked}
        deleteModalVisible={handleDeleteModalVisible(true)}
      />
      <Box m={4}>
        <Grid container>
          <Grid item xs={9}>
            <TextField
              className="redesign"
              variant="standard"
              type="text"
              label="Name"
              placeholder="Enter name"
              name="name"
              autoComplete="off"
              value={activePayment.name}
              inputRef={firstInputElement}
              required
              permissions={editPermission}
              onChange={handleTextInputChangedWithValidation}
              error={!!errors.name}
              dataQa="payment-term-name"
            />
          </Grid>
        </Grid>
        <Box mt={2}>
          <Grid container spacing={4}>
            <Grid item>
              <Grid container direction="column">
                <Box mb={2}>
                  <RadioGroup
                    value={activePayment.paymentTermType}
                    onChange={handleChangeType}
                    row
                  >
                    <FormControlLabel
                      disabled={!canClick}
                      value={PaymentTermType.Standard}
                      control={<Radio color="primary" />}
                      label="Net Term"
                      data-qa="payment-term-net-term"
                    />
                    <FormControlLabel
                      disabled={!canClick}
                      value={PaymentTermType.DateDriven}
                      control={<Radio color="primary" />}
                      label="Date Driven"
                      data-qa="payment-term-date-driven"
                    />
                  </RadioGroup>
                </Box>
                {isNetTermSelected ? (
                  <Grid item>
                    <Box display="flex" alignItems="baseline" mb={2}>
                      <TextField
                        className={`redesign ${classes.dayInput}`}
                        variant={'standard'}
                        type="number"
                        name="dueDays"
                        label="Net Days"
                        fullWidth={false}
                        permissions={editPermission}
                        value={activePayment.dueDays}
                        onChange={handleTextInputChangedWithValidation}
                        error={!!errors.dueDays}
                        disabled={!isNetTermSelected}
                        dataQa="payment-term-net-days"
                      />
                    </Box>
                  </Grid>
                ) : (
                  <>
                    <Grid item>
                      <Box display="flex" alignItems="baseline" mb={2}>
                        <TextField
                          className={`redesign ${classes.dayInput}`}
                          variant={'standard'}
                          type="number"
                          name="dayOfMonthDue"
                          label="Date Due"
                          inputProps={{ max: 28 }}
                          fullWidth={false}
                          permissions={editPermission}
                          value={activePayment.dayOfMonthDue}
                          onChange={handleTextInputChangedWithValidation}
                          error={!!errors.dayOfMonthDue}
                          disabled={isNetTermSelected}
                        />
                        <Box ml={2} mr={2}>
                          <Typography color="textPrimary">
                            each month.
                          </Typography>
                        </Box>
                        <Box mr={2}>
                          <Typography color="textPrimary">
                            Due the following month if issued within
                          </Typography>
                        </Box>
                        <Box display="flex" alignItems="baseline">
                          <TextField
                            className={`redesign ${classes.dayInput}`}
                            variant={'standard'}
                            type="number"
                            name="graceDays"
                            label="Days"
                            inputProps={{ max: 28 }}
                            fullWidth={false}
                            permissions={editPermission}
                            value={activePayment.graceDays}
                            onChange={handleTextInputChangedWithValidation}
                            error={!!errors.graceDays}
                          />
                        </Box>
                        <Box ml={2}>
                          <Typography color="textPrimary">
                            of due date.
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  </>
                )}
                <Grid item>
                  <Box mb={2}>
                    <FormControlLabel
                      value="applyDiscountSelected"
                      name="discount"
                      control={
                        <Checkbox
                          className={'redesign'}
                          color="primary"
                          inputProps={
                            {
                              'data-qa': 'payment-term-apply-discount',
                            } as any
                          }
                        />
                      }
                      disabled={!canClick}
                      label="Apply discount if paid early"
                      checked={applyDiscountSelected}
                      onChange={handleApplyDiscount}
                    />
                  </Box>
                </Grid>
                <Grid item>
                  <Box display="flex" alignItems="baseline">
                    <TextField
                      className={`redesign ${classes.dayInput}`}
                      variant={'standard'}
                      type="number"
                      name="discountPercent"
                      label="Discount"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment
                            disableTypography={
                              !isNetTermSelected || !applyDiscountSelected
                            }
                            position="end"
                          >
                            %
                          </InputAdornment>
                        ),
                      }}
                      fullWidth={false}
                      permissions={editPermission}
                      value={activePayment.discountPercent}
                      onChange={handleTextInputChangedWithValidation}
                      error={!!errors.discountPercent}
                      disabled={!applyDiscountSelected}
                      dataQa="payment-term-discount-percent"
                    />
                    <Box ml={2} mr={2}>
                      <Typography
                        color={
                          !applyDiscountSelected
                            ? 'textSecondary'
                            : 'textPrimary'
                        }
                      >
                        if paid within
                      </Typography>
                    </Box>
                    <TextField
                      className={`redesign ${classes.dayInput}`}
                      variant={'standard'}
                      type="number"
                      name="discountDays"
                      label="Days"
                      fullWidth={false}
                      permissions={editPermission}
                      value={activePayment.discountDays}
                      onChange={handleTextInputChangedWithValidation}
                      error={!!errors.discountDays}
                      disabled={!applyDiscountSelected}
                      dataQa="payment-term-discount-days"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <ConfirmationModal
        open={deleteModalVisible}
        title="Delete Payment Term"
        body={`This will delete '${
          _.get(activePayment, 'name') || 'Payment Term'
        }', are you sure?`}
        onCancelClicked={handleDeleteModalVisible(false)}
        onConfirmClicked={handleDeleteConfirm}
        confirmLabel={'Delete'}
        cancelLabel={'Cancel'}
        confirmButtonRed
      />
    </DetailsCard>
  );
};

export default PaymentTermsDetailsCard;
