import React, { memo, useCallback, useMemo, useState } from 'react';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {
  useHandleCheckboxChange,
  useHandleTextFieldChange,
} from 'services/forms/hooks';
import { Item } from 'services/items';

import { ReportFiltersProps } from '../../types';
import { ItemType, labelTypes, producedFromTypes } from './consts';
import { LabelType, ProducedFromType } from './types';

import { ItemsAutocomplete } from 'ui/components/Autocomplete/ItemsAutocomplete';
import { Autocomplete } from 'ui/components/Autocomplete/Autocomplete';
import { CountComponent } from '../CountComponent/CountComponent';
import { SaleItem } from 'services/items/saleItems';
import { SaleItemAsyncAutocomplete } from 'ui/components/Autocomplete/SaleItemAsyncAutocomplete';

const ItemBarcodeOneOffFilters: React.FC<ReportFiltersProps> = (props) => {
  const { parameters, setParameters, errors } = props;
  const [itemType, setItemType] = useState(ItemType.Item);

  const selectedProducedFromType = useMemo(() => {
    return (
      producedFromTypes.find((o) => o.type === parameters.producedFromType) ||
      null
    );
  }, [parameters]);

  const selectedLabelType = useMemo(() => {
    return labelTypes.find((o) => o.type === parameters.labelType) || null;
  }, [parameters]);

  const handleSelectAll = useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setParameters((old) => ({
        ...old,
        showDescription: checked,
        showPrice: checked,
        showUpc: checked,
        selectAllOptional: checked,
      }));
    },
    [setParameters]
  );

  const handleCheckboxChange = useHandleCheckboxChange(setParameters);
  const handleItemChange = useCallback(
    (selectedItem: Item | null) => {
      setParameters((old) => ({
        ...old,
        itemId: selectedItem ? selectedItem.id : null,
      }));
    },
    [setParameters]
  );
  const handleSaleItemChange = useCallback(
    (saleItem: SaleItem | null) => {
      setParameters((old) => ({
        ...old,
        SaleItemId: saleItem ? saleItem.id : null,
      }));
    },
    [setParameters]
  );
  const handleTextFieldChange = useHandleTextFieldChange(
    setParameters,
    parameters
  );

  const handleProducedFromTypeChange = useCallback(
    (_e: any, type: ProducedFromType | null) => {
      setParameters((old) => ({
        ...old,
        producedFromType: type ? type.type : null,
      }));
    },
    [setParameters]
  );

  const handleLabelTypeChange = useCallback(
    (_e: any, labelType: LabelType | null) => {
      setParameters((old) => ({
        ...old,
        labelType: labelType ? labelType.type : null,
        labelWidth: labelType ? labelType.width : null,
        labelHeight: labelType ? labelType.height : null,
      }));
    },
    [setParameters]
  );
  const handleItemTypeChange = useCallback(
    (_e: React.ChangeEvent<{}>, value: ItemType | null) => {
      if (value === ItemType.SaleItem) {
        setItemType(ItemType.SaleItem);
        setParameters((old) => {
          const { itemId, ...rest } = old;
          return {
            ...rest,
            type: 'saleitem',
            SaleItemId: itemId,
          };
        });
        handleItemChange(null);
      } else {
        setItemType(ItemType.Item);

        setParameters((old) => {
          const { SaleItemId, ...rest } = old;
          return {
            ...rest,
            type: ItemType.Item,
            itemId: SaleItemId,
          };
        });
      }
    },
    [setParameters, handleItemChange]
  );
  // TODO : RESTYLING : Remove box wrappers around inputs after FF removal

  return (
    <>
      <Box px={2} pb={0} pt="16px">
        <FormControlLabel
          className="redesign"
          control={
            <Checkbox
              className="redesign"
              checked={Boolean(parameters.selectAllOptional)}
              onChange={handleSelectAll}
              color="primary"
              data-qa="barcode-oneOff-report-select-all-optional"
            />
          }
          label="Select All Optional"
        />
      </Box>
      <Accordion square defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          data-qa="barcode-oneOff-report-select-all-optional-expand"
        >
          <Typography>
            <b>Optional</b>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column" width="100%">
            <Box>
              <Autocomplete
                label="Labels"
                options={labelTypes}
                value={selectedLabelType}
                onChange={handleLabelTypeChange}
                getOptionLabel={(i) => i.title}
                placeholder="Select label"
                required
                error={!!errors.labelType}
                helperText={errors.labelType}
                dataQa="barcode-oneOff-report-labels"
                ListboxProps={{
                  role: 'list-box',
                }}
              />
            </Box>
            <Box>
              <Autocomplete
                options={Object.values(ItemType)}
                placeholder="Item Type"
                label="Item Type"
                onChange={handleItemTypeChange}
                value={itemType}
                fullWidth
                dataQa="barcode-oneOff-report-produced-from-type"
              />
            </Box>
            {itemType === 'Item' ? (
              <Box>
                <ItemsAutocomplete
                  label="Item"
                  placeholder="Select item"
                  onChange={handleItemChange}
                  value={parameters.itemId}
                  dataQa="barcode-oneOff-report-select-item"
                />
              </Box>
            ) : (
              <Box>
                <SaleItemAsyncAutocomplete
                  label="Sale Item"
                  onChange={handleSaleItemChange}
                  value={parameters.saleItemId || parameters.itemId}
                  placeholder="Select sale item"
                  dataQa="barcode-oneOff-report-select-sale-item"
                  isBundle={!parameters.saleItemId}
                />
              </Box>
            )}
            <CountComponent
              parameters={parameters}
              handleTextFieldChange={handleTextFieldChange}
            />
            <Box>
              <Autocomplete
                label="Produced From Type"
                options={producedFromTypes}
                placeholder="Enter produced from type"
                getOptionLabel={(i) => i.title}
                onChange={handleProducedFromTypeChange}
                value={selectedProducedFromType}
                fullWidth
                dataQa="barcode-oneOff-report-produced-from-type"
              />
            </Box>
            <FormControlLabel
              className="redesign"
              sx={{ marginBottom: '8px' }}
              control={
                <Checkbox
                  className="redesign"
                  checked={Boolean(parameters.showDescription)}
                  onChange={handleCheckboxChange}
                  color="primary"
                  name="showDescription"
                  data-qa="barcode-oneOff-report-show-description"
                />
              }
              label="Show Description"
            />
            <FormControlLabel
              className="redesign"
              sx={{ marginBottom: '8px' }}
              control={
                <Checkbox
                  className="redesign"
                  checked={Boolean(parameters.showPrice)}
                  onChange={handleCheckboxChange}
                  color="primary"
                  name="showPrice"
                  data-qa="barcode-oneOff-report-show-price"
                />
              }
              label="Show Price"
            />
            <FormControlLabel
              className="redesign"
              sx={{ marginBottom: '8px' }}
              control={
                <Checkbox
                  className="redesign"
                  checked={Boolean(parameters.showUpc)}
                  onChange={handleCheckboxChange}
                  color="primary"
                  name="showUpc"
                  data-qa="barcode-oneOff-report-show-upc"
                />
              }
              label="Show UPC"
            />
          </Box>
        </AccordionDetails>
      </Accordion>
    </>
  );
};

export default memo(ItemBarcodeOneOffFilters);
