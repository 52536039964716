import { ReactNode } from 'react';
import { InternationalTaxRate } from 'services/integrations/xero';
import { ChannelTaxType } from 'services/taxRates';

export interface InternationalTaxRatesProps {
  accountingType: string;
  label: string;
  optionsFilter?: (taxRate: InternationalTaxRate) => boolean;
  selectedOptionCallback?: (
    taxRate: InternationalTaxRate,
    index?: number
  ) => boolean;
  children?: ReactNode;
  index?: number;
  selectedValue?: InternationalTaxRate | null;
  dataQa?: string;
  error?: boolean;
}
export interface TaxRateMappingModalProps {
  open: boolean;
  onClose: () => void;
}
export interface TaxMappingRates {
  taxId: number;
  channelsTaxRate: ChannelTaxRate;
}
export interface ChannelTaxRate {
  name: string;
  rate: number;
  code: string;
  taxType: ChannelTaxType;
}
export enum routes {
  AddNewTaxRates = '/setup/tax-rates?activeId=-1',
}
export interface TaxRateMappingModalProps {
  open: boolean;
  onClose: () => void;
}
export interface TaxMappingRates {
  taxId: number;
  channelsTaxRate: ChannelTaxRate;
}
export interface ChannelTaxRate {
  name: string;
  rate: number;
  code: string;
  taxType: ChannelTaxType;
}
