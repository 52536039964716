import { PermissionGroup } from './types';

export const initialPermissionGroup: PermissionGroup = {
  id: null,
  dateCreated: null,
  dateLastModified: null,
  name: null,
  permissionIds: [],
  permissions: [],
  readOnly: false,
  users: [],
  version: null,
};

export enum permissionCategory {
  GENERAL = 'General',
  MATERIALS = 'Materials',
  SALES = 'Sales',
  PURCHASING = 'Purchasing',
  SETUP = 'Setup',
  SETTINGS = 'Settings',
  REPORTS = 'Reports',
}

export enum permissionArea {
  REPORTS = 'Reports',
}

export const settingsPermissionsOrder: string[] = [
  'Sales Order',
  'Purchase Order',
  'Picking',
  'Tracking',
  'Shipping',
  'Account Mapping',
  'Company',
  'Multicurrency',
  'Emailing',
];

export const setupPermissionsOrder: string[] = [
  'UOMs',
  'Locations',
  'Users',
  'Permission Groups',
  'Payment Terms',
  'Payment Types',
  'Currency',
  'Representatives',
  'Tax Rates',
  'Carriers',
];
