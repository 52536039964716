import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';

import { PaperSlidingLayout } from 'ui/components/Paper/PaperSlidingLayout';
import { Page } from 'ui/components/Page/Page';
import { Pagination } from 'services/search';

import { ChannelDetailsCard, ChannelTable } from './components';
import { initialPagination } from './consts';
import { MyChannelsPageCmp } from './types';
import { Routes } from '../../navigation';
import { Channel, getChannels } from 'services/commerce/channels';
import { logErrorCtx } from 'app/logging';
import { GlobalSettingsBanner } from '../../components/GlobalSettingsBanner';
import {
  getCommerceAccountIsConfigured,
  getCommerceAccountIsDisconnected,
  getCommerceAccountIsOrWasConnected,
} from 'services/commerce';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveUser } from 'services/user';
import { getSellwareUrl } from '../DiscoverChannels/helpers';
import { ReconnectBanner } from '../../components/ReconnectBanner';
import { useUrlQueryObject } from 'services/url';
import { getSettingsCompany } from 'services/settings/company';
import { useFlags } from 'helpers/useFlags';
import { useLocation, useHistory } from 'react-router-dom';
import { showNotification } from 'services/api';
import { register, reconnect } from 'services/commerce/api';
import { CreateAccountModal } from '../DiscoverChannels/components/CreateAccountModal';
import { DiscoverChannelsHeroComponent } from '../DiscoverChannels/components/DiscoverChannelsHeroComponent';
import { ReconnectingModal } from '../DiscoverChannels/components/ReconnectingModal';
import { SuccessModal } from '../DiscoverChannels/components/SuccessModal';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const MyChannelsPage: MyChannelsPageCmp = () => {
  const [channels, setChannels] = useState<Channel[]>([]);
  const [activeId, setActiveId] = useState<number | null>(null);
  const [pagination, setPagination] = useState<Pagination>(initialPagination);
  const [loadingChannels, setLoadingChannels] = useState(false);
  const [sellwareUrlForReconnectFlow, setSellwareUrlForReconnectFlow] =
    useState<string>('');
  const [createAccountModalOpen, setCreateAccountModalOpen] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [reconnectingModalOpen, setReconnectingModalOpen] = useState(false);
  const [sellwareUrlForRegisterFlow, setSellwareUrlForRegisterFlow] =
    useState<string>('');

  const [, extendUrlQuery] = useUrlQueryObject();

  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(location.search);

  const flags = useFlags();

  useEffect(() => {
    const tempActiveId = queryParams?.get('activeId');
    if (tempActiveId) {
      setActiveId(Number(tempActiveId));
    } else {
      setActiveId(null);
    }
  }, [queryParams]);

  // reorder data when pagination sort changed
  useEffect(() => {
    setChannels(
      _.orderBy(channels, pagination.sort.sortBy, pagination.sort.direction)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.sort]);

  useEffect(() => {
    getMyChannels();
  }, []);

  const getMyChannels = useCallback(async () => {
    setLoadingChannels(true);

    try {
      const res = await getChannels();
      setChannels(
        _.orderBy(res, pagination.sort.sortBy, pagination.sort.direction)
      );
    } catch (e) {
      logErrorCtx('Error getting Commerce Channels', {
        error: e as Error,
        component: 'MyChannelsPage',
      });
    }

    setLoadingChannels(false);
  }, [pagination]);

  const handleClose = useCallback(() => {
    extendUrlQuery({ activeId: null });
  }, [extendUrlQuery]);

  const isSellwareConfigured = useSelector(getCommerceAccountIsConfigured);

  const isSellwareDisconnected = useSelector(getCommerceAccountIsDisconnected);

  const activeUser = useSelector(getActiveUser);

  const companySettings = useSelector(getSettingsCompany);
  const companyName = _.get(companySettings, 'name', '');

  const isOrWasConnectedToSellware = useSelector(
    getCommerceAccountIsOrWasConnected
  );

  const showReconnectBanner =
    isSellwareDisconnected && isOrWasConnectedToSellware;

  const showGlobalSettingsBanner =
    !isSellwareConfigured && isOrWasConnectedToSellware && !showReconnectBanner;

  useEffect(() => {
    const registrationUrl =
      (flags.commerceAuth0Enabled
        ? process.env.REACT_APP_COMMERCE_REGISTER_URL
        : process.env.REACT_APP_SELLWARE_REGISTER_URL) || '';

    const getSellwareUrlForRegisterFlow = async () => {
      const url = await getSellwareUrl(
        activeUser,
        companyName,
        true,
        undefined,
        registrationUrl
      );
      setSellwareUrlForRegisterFlow(url);
    };

    const getSellwareUrlForReconnectFlow = async () => {
      const url = await getSellwareUrl(
        activeUser,
        companyName,
        false,
        true,
        registrationUrl
      );
      setSellwareUrlForReconnectFlow(url);
    };

    if (showReconnectBanner && activeUser && companyName) {
      getSellwareUrlForReconnectFlow();
    } else if (!isOrWasConnectedToSellware && activeUser && companyName) {
      getSellwareUrlForRegisterFlow();
    }
  }, [activeUser, showReconnectBanner, companyName, flags]);

  useEffect(() => {
    const callRegistration = async () => {
      if (location.search) {
        const queryParams = new URLSearchParams(location.search);

        const tenantId = queryParams?.get('tenantId');
        const appId = queryParams?.get('appId');
        const apiSecret = queryParams?.get('apiSecret');
        const registerFlow = queryParams?.get('register');

        if (tenantId && appId && apiSecret && registerFlow) {
          history.replace({
            search: '',
          });
          setCreateAccountModalOpen(true);

          try {
            await register(tenantId, appId, apiSecret);

            setSuccessModalOpen(true);
            setCreateAccountModalOpen(false);
            setTimeout(() => {
              // @ts-ignore
              dispatch(fetchSellwareActiveAccount());
            }, 1000);
          } catch (e) {
            setCreateAccountModalOpen(false);
            showNotification(
              'There was an error connecting to Fishbowl Commerce. Try again later.',
              {
                variant: 'error',
              }
            );
          }
        }
      }
    };

    if (location.search) {
      callRegistration();
    }
  }, []);

  useEffect(() => {
    const callReconnect = async () => {
      const queryParams = new URLSearchParams(location.search);

      const tenantId = queryParams?.get('tenantId');
      const appId = queryParams?.get('appId');
      const apiSecret = queryParams?.get('apiSecret');
      const reconnectFlow = queryParams?.get('reconnect');

      if (tenantId && appId && apiSecret && reconnectFlow) {
        history.replace({
          search: '',
        });
        setReconnectingModalOpen(true);

        try {
          await reconnect(tenantId, appId, apiSecret);

          setReconnectingModalOpen(false);

          setTimeout(() => {
            // @ts-ignore
            dispatch(fetchSellwareActiveAccount());
          }, 1000);

          showNotification(
            'Your Fishbowl Commerce account has been reconnected.',
            {
              variant: 'success',
            }
          );
        } catch (e) {
          setReconnectingModalOpen(false);

          showNotification(
            'There was an error reconnecting to Fishbowl Commerce. Try again later.',
            {
              variant: 'error',
            }
          );
        }
      }
    };

    if (location.search) {
      callReconnect();
    }
  }, []);

  const handleCloseSuccessModal = () => {
    setSuccessModalOpen(false);
  };

  useEffect(() => {
    const getSellwareUrlForReconnectFlow = async () => {
      const url = await getSellwareUrl(activeUser, companyName, false, true);
      setSellwareUrlForReconnectFlow(url);
    };

    if (showReconnectBanner && activeUser && companyName) {
      getSellwareUrlForReconnectFlow();
    }
  }, [activeUser, companyName, showReconnectBanner]);

  const handleChannelClick = useCallback(
    async (channelId: number) => {
      extendUrlQuery({ activeId: channelId });
    },
    [extendUrlQuery]
  );

  return (
    <>
      {showReconnectBanner && (
        <ReconnectBanner sellwareUrl={sellwareUrlForReconnectFlow} />
      )}
      {showGlobalSettingsBanner && <GlobalSettingsBanner />}

      {flags.commerceIntegrationUiRefactor &&
        (isSellwareDisconnected || !isOrWasConnectedToSellware) && (
          <>
            <DiscoverChannelsHeroComponent
              sellwareUrl={sellwareUrlForRegisterFlow}
            />
            <CreateAccountModal open={createAccountModalOpen} />
            <SuccessModal
              open={successModalOpen}
              handleClose={handleCloseSuccessModal}
            />
            <ReconnectingModal open={reconnectingModalOpen} />
          </>
        )}

      {(flags.commerceIntegrationUiRefactor &&
        isOrWasConnectedToSellware &&
        !isSellwareDisconnected) ||
      !flags.commerceIntegrationUiRefactor ? (
        <Page>
          <PaperSlidingLayout shown={Boolean(activeId)}>
            <ChannelTable
              items={channels}
              activeItemId={activeId}
              handleItemClick={handleChannelClick}
              isLoadingItems={loadingChannels}
              pagination={pagination}
              onPaginationChange={setPagination}
            />
            <ChannelDetailsCard
              activeItemId={activeId}
              channels={channels}
              onClose={handleClose}
              fetchChannels={getMyChannels}
            />
          </PaperSlidingLayout>
        </Page>
      ) : null}
    </>
  );
};

MyChannelsPage.route = Routes.MyChannelsPage;

export default MyChannelsPage;
