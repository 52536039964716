import React, { useMemo, useCallback, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { TableCell, Box, Typography, Tooltip } from '@mui/material';
import { IconNames, colorPalette } from 'ui/theme';
import { RenderCustomRowProps } from 'ui/components/Table/ItemsTable';
import { MenuItem, MenuItemVariant } from 'ui/components/Menu';
import { ImageItemBox } from 'ui/components/Image/ImageItemBox';
import { StatusLabel } from 'ui/components/Status/StatusLabel';
import {
  getPickItemDescription,
  getPickItemName,
  PickItem,
  PickItemQuantities,
  PickItemStatus,
} from 'services/picking';
import { getUomById } from 'services/uoms';
import { ItemType } from 'services/items';
import { PermissionType } from 'services/permissions';
import { showThumbnail } from 'services/thumbnail/redux';

import { RowActionIdentifier } from '../../types';
import {
  displayStatus,
  shouldDisableCommit,
  shouldDisabledHold,
  shouldDisableFinish,
  shouldDisableStart,
  shouldDisableVoid,
} from './helpers';

import FBOButton from 'ui/theme/components/FBOButton/FBOButton';
import FBOMenuButton from 'ui/theme/components/FBOMenuButton/FBOMenuButton';

const PickItemRow: React.FC<RenderCustomRowProps<PickItem>> = (props) => {
  const {
    row: pickItem,
    onAction,
    columns,
    meta: { pickItemsQuantities },
  } = props;

  const unitOfMeasurement = useSelector(getUomById(pickItem.uomId));

  const dispatch = useDispatch();

  const handleOpenPreview = useCallback(
    (e) => {
      const imageUrl = _.get(pickItem, 'item.defaultImage', '') as string;

      dispatch(showThumbnail(imageUrl));
      e.stopPropagation();
    },
    [dispatch, pickItem]
  );

  const itemHasTracking = useMemo(
    () => _.some(_.get(pickItem, 'item.itemTrackingTypeList', [])),
    [pickItem]
  );

  const isInventoryType =
    _.get(pickItem, 'item.itemType') === ItemType.Inventory;

  const MENU_ACTIONS: MenuItem[] = useMemo(
    () => [
      {
        icon: IconNames.FBOPlayCircleSmall,
        label: 'Start',
        dataQa: 'picking-item-row-menu-start',
        disabled: shouldDisableStart(pickItem.status),
        permissions: [PermissionType.PickingEdit],
        separateAfter: true,
        onOptionClick: (ev: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
          if (shouldDisableStart(pickItem.status)) return false;

          ev.stopPropagation();

          if (onAction) {
            const data = {
              pickItem,
              identifier: RowActionIdentifier.Start,
            };
            onAction(data);
          }
        },
      },
      {
        icon: IconNames.FBOLockedSmall,
        label: 'Commit',
        dataQa: 'picking-item-row-menu-commit',
        permissions: [PermissionType.PickingCommit],
        disabled: shouldDisableCommit(pickItem),
        separateAfter: true,
        onOptionClick: (ev: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
          if (shouldDisableCommit(pickItem)) return false;

          ev.stopPropagation();

          if (onAction) {
            const data = {
              pickItem,
              identifier: RowActionIdentifier.Commit,
            };
            onAction(data);
          }
        },
      },
      {
        icon: IconNames.FBOCircleCheckedSmall,
        label: 'Finish',
        dataQa: 'picking-item-row-menu-finish',
        permissions: [PermissionType.PickingFinish],
        disabled: shouldDisableFinish(pickItem),
        separateAfter: true,
        onOptionClick: (ev: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
          if (shouldDisableFinish(pickItem)) return false;

          ev.stopPropagation();

          if (onAction) {
            const data = {
              pickItem,
              identifier: RowActionIdentifier.Finish,
            };
            onAction(data);
          }
        },
      },
      {
        icon: IconNames.FBOPauseCircleSmall,
        label: 'Hold',
        dataQa: 'picking-item-row-menu-hold',
        disabled: shouldDisabledHold(pickItem.status),
        permissions: [PermissionType.PickingEdit],
        separateAfter: true,
        onOptionClick: (ev: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
          if (shouldDisabledHold(pickItem.status)) return false;

          ev.stopPropagation();

          if (onAction) {
            const data = {
              pickItem,
              identifier: RowActionIdentifier.Hold,
            };
            onAction(data);
          }
        },
      },
      {
        icon:
          pickItem.status === PickItemStatus.Hold
            ? IconNames.FBORedoSmall
            : IconNames.FBOStopCircleSmall,
        label: pickItem.status === PickItemStatus.Hold ? 'Resume' : 'Void',
        disabled: shouldDisableVoid(pickItem.status),
        permissions: [PermissionType.PickingEdit],
        variant: PickItemStatus.Hold
          ? MenuItemVariant.Default
          : MenuItemVariant.Danger,
        onOptionClick: (ev: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
          if (shouldDisableVoid(pickItem.status)) return false;

          ev.stopPropagation();

          if (onAction) {
            const data = {
              pickItem,
              identifier: RowActionIdentifier.Void,
            };
            onAction(data);
          }
        },
      },
    ],
    [onAction, pickItem]
  );

  const handleTrackingIconClicked = useCallback(
    (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.stopPropagation();

      if (onAction) {
        onAction({ identifier: RowActionIdentifier.ShowTracking, pickItem });
      }
    },
    [pickItem, onAction]
  );

  const itemQuantities: PickItemQuantities = pickItemsQuantities?.find(
    (itemQuantityInfo: PickItemQuantities) =>
      itemQuantityInfo?.itemId === pickItem?.itemId
  );

  const itemQuantityAvailableToPick = itemQuantities?.availableToPickQty;

  const pickItemDisplayStatus = displayStatus(
    pickItem,
    itemQuantityAvailableToPick ?? 0
  );

  const showQuantityAvailable = !(
    pickItem.status === PickItemStatus.Committed ||
    pickItem.status === PickItemStatus.Finished
  );

  return (
    <>
      <TableCell style={{ width: columns[0].width }}>
        <ImageItemBox
          source={_.get(pickItem, 'item.defaultImage', null)}
          name={getPickItemName(pickItem)}
          description={getPickItemDescription(pickItem)}
          onClick={handleOpenPreview}
        />
      </TableCell>
      <TableCell>
        {pickItem.quantity !== null && (
          <StatusLabel status={pickItemDisplayStatus} />
        )}
      </TableCell>

      <TableCell>
        <Box display="flex">
          <Typography variant="body2">{pickItem.quantity}</Typography>
          <Box ml={1}>
            <Typography variant="body2" color="textSecondary">
              {unitOfMeasurement && unitOfMeasurement.abbreviation}
            </Typography>
          </Box>
        </Box>
        {isInventoryType && showQuantityAvailable && (
          <Typography color="textSecondary" variant="caption">
            {`${itemQuantityAvailableToPick ?? 0} Available`}
          </Typography>
        )}
      </TableCell>
      <TableCell>
        {isInventoryType && (
          <>
            <Typography variant="body2" noWrap>
              {pickItem.pickFromLocation &&
                pickItem.pickFromLocation.displayName}
            </Typography>
            <Typography color="textSecondary" variant="caption">
              {`${pickItem.fromLocationQuantityAvailable || 0} at Location`}
            </Typography>
          </>
        )}
      </TableCell>
      <TableCell>
        <Typography variant="body2" noWrap>
          {pickItem.pickToLocation && pickItem.pickToLocation.displayName}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="body2">{pickItem.slotNumber}</Typography>
      </TableCell>
      <TableCell>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="flex-end"
          mr={2}
          flexDirection="row"
        >
          {itemHasTracking && (
            <FBOButton
              variant="tertiary"
              color="neutral"
              size="small"
              icon={IconNames.TrackingAim}
              disabled={
                pickItem.status === PickItemStatus.Committed ||
                pickItem.status === PickItemStatus.Finished
                  ? false
                  : true
              }
              onClick={handleTrackingIconClicked}
              style={{ marginBottom: '2px' }}
              data-qa="pick-item-row-track-changes-button"
            />
          )}
          <Tooltip title={pickItem.notes || ''}>
            <div>
              <FBOButton
                variant="tertiary"
                color="neutral"
                size="small"
                icon={IconNames.IconNote}
                disabled={!!pickItem.notes?.length}
                data-qa="picking-comment-icon"
              />
            </div>
          </Tooltip>
        </Box>
      </TableCell>
      <TableCell>
        <FBOMenuButton
          variant="tertiary"
          items={MENU_ACTIONS}
          data-qa="picking-menu-actions"
          style={{ color: colorPalette.redesign.contentSecondary }}
        />
      </TableCell>
    </>
  );
};

export default memo(PickItemRow);
