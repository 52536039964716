import { transformDateToMomentDate } from 'helpers';

import { PaymentTerm } from './types';

export const transformPaymentTerm = (response: any): PaymentTerm => {
  return {
    id: response.id,
    dateCreated: transformDateToMomentDate(response.dateCreated),
    dateLastModified: transformDateToMomentDate(response.dateLastModified),
    dayOfMonthDue: response.dayOfMonthDue,
    deleted: response.deleted,
    discountDays: response.discountDays,
    discountPercent: response.discountPercent,
    dueDays: response.dueDays,
    graceDays: response.graceDays,
    name: response.name,
    paymentTermType: response.paymentTermType,
    version: response.version,
  };
};

export const transformPaymentTermRequest = (paymentTerm: PaymentTerm): any => {
  // POST PAYMENT TERM
  if (paymentTerm.id && paymentTerm.id < 0) {
    return {
      dayOfMonthDue: paymentTerm.dayOfMonthDue,
      deleted: paymentTerm.deleted,
      discountDays: paymentTerm.discountDays,
      discountPercent: paymentTerm.discountPercent,
      dueDays: paymentTerm.dueDays,
      graceDays: paymentTerm.graceDays,
      name: paymentTerm.name,
      paymentTermType: paymentTerm.paymentTermType,
    };
  }

  // PUT PAYMENT TERM
  return {
    id: paymentTerm.id,
    dayOfMonthDue: paymentTerm.dayOfMonthDue,
    deleted: paymentTerm.deleted,
    discountDays: paymentTerm.discountDays,
    discountPercent: paymentTerm.discountPercent,
    dueDays: paymentTerm.dueDays,
    graceDays: paymentTerm.graceDays,
    name: paymentTerm.name,
    paymentTermType: paymentTerm.paymentTermType,
    version: paymentTerm.version,
  };
};
