import React, { memo, useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import AutoComplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { CircularProgress } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import _ from 'lodash';

import { fetchUserById, fetchUsersAPI, User } from 'services/user';
import { TextField } from 'ui/components/TextField/TextField';

import { UsersAutocompleteProps } from './types';

const UsersAutocomplete: React.FC<UsersAutocompleteProps> = (props) => {
  const {
    label,
    value,
    placeholder,
    required,
    onChange,
    removedIds = [],
    dataQa,
    additionalInputProps,
    classes,
    disabled = false,
    sx = {},
  } = props;

  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState<string | null>(null);
  const [users, setUsers] = useState<User[]>([]);
  const [fetchLoading, setFetchLoading] = useState(false);
  const [innerValue, setInnerValue] = useState<User | null>(null);

  useEffect(() => {
    if (typeof value === 'number') {
      if (!innerValue) {
        (async () => {
          const resUser = await fetchUserById(value);
          setInnerValue(resUser);
        })();
      }
    } else {
      setInnerValue(value);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const getUsers = useDebouncedCallback(async () => {
    setFetchLoading(true);

    const resUsers = (
      await fetchUsersAPI({
        quickSearchValue: searchValue,
      })
    ).data;
    // don't show removedIds
    const filteredUsers = resUsers.filter((u) => !removedIds.includes(u.id!));

    setUsers(filteredUsers);
    setFetchLoading(false);
  }, 300);

  useEffect(() => {
    // when autocomplete values is changed and it's not null
    // or when autocomplete is opened and doesn't have any data
    // we want to fetch items
    if (searchValue || (open && !users.length)) {
      getUsers();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue, open]);

  const handleAutocompleteInputChange = (
    e: any,
    val: string,
    reason: string
  ) => {
    // reason why this event is triggered
    // it can be 'input', 'clear' and 'reset'
    if (reason === 'input') {
      setSearchValue(val || null);
      return;
    }

    setSearchValue(null);
  };

  const handleChange = (e: any, user: User | null) => {
    setInnerValue(user);
    onChange(user);
  };

  return (
    <>
      <AutoComplete
        sx={sx}
        className="redesign"
        options={users}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        onChange={handleChange}
        renderInput={(params) => {
          const endAdornment = params.InputProps.endAdornment as any;

          return (
            <TextField
              // we are omitting props that would overwrite our styling in TextField
              {..._.omit(params, 'variant', 'size', 'InputLabelProps')}
              label={label}
              placeholder={placeholder}
              required={required}
              InputProps={{
                ...params.InputProps,
                ...additionalInputProps,
                endAdornment: (
                  <React.Fragment>
                    {fetchLoading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {endAdornment}
                  </React.Fragment>
                ),
              }}
              inputProps={{
                'data-qa': dataQa,
                'data-lpignore': 'true',
                ...params.inputProps,
              }}
            />
          );
        }}
        filterOptions={(options, params) =>
          createFilterOptions<User>()(options, params)
        }
        onInputChange={handleAutocompleteInputChange}
        getOptionLabel={(user) => `${user.firstName} ${user.lastName}`}
        isOptionEqualToValue={(option, val) => option.id === val.id}
        disabled={disabled}
        value={innerValue}
        classes={classes}
        autoSelect
        clearIcon={
          dataQa && (
            <CloseIcon
              fontSize="small"
              data-qa={`${dataQa}-autocomplete-clear-icon`}
            />
          )
        }
        popupIcon={
          dataQa && (
            <ArrowDropDownIcon
              fontSize="small"
              data-qa={`${dataQa}-autocomplete-dropdown-icon`}
            />
          )
        }
      />
    </>
  );
};

export default memo(UsersAutocomplete);
